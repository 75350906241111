import React, {useEffect, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {useNavigate, useParams} from 'react-router-dom';
import {convertToDate, formatMMDDYYYY} from '../../api/dates';
import ActionPopup from '../../components/action-feedback-popup/action-feedback-popup';
import {TagsRadarChart} from '../../components/charts/radar-chart';
import {ProfileImage} from '../../components/inputs/uploader';
import {OrganizationItemPreview} from '../../components/item-details/organization-item';
import {SpinningIndicator} from '../../components/loading/loading-indicator';
import {SubscribeModal} from '../../components/modal/schedule-modal';
import {YourReferer} from '../../components/referral/yourReferer';
import {Tab} from '../../components/tab/tab';
import {
  DisplayField,
  ProfileTagDisplay,
} from '../../components/text/display-fields';
import {useAuth} from '../../hooks/use-auth';
import useCrypto from '../../hooks/use-crypto';
import useLoader from '../../hooks/use-loader';
import useStringFormatter from '../../hooks/use-string-formatter';
import {isValidEmail} from '../../utils/utils';
import './styles.css';

const ProfileDetail = ({}) => {
  const {id} = useParams();
  const {loadProfiles, loadOrgs} = useLoader();
  const navigate = useNavigate();
  const {decryptString} = useCrypto();

  const {
    state: {profiles},
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [parsedId, setParsedID] = useState(null);

  const profile = profiles?.[parsedId] ?? {};
  const {organization_ids, calendar_link} = profile;
  const [index, setIndex] = useState(0);

  const tabs = [
    {tab: 'Requests', index: 0},
    {tab: 'Resources', index: 1},
  ];

  useEffect(() => {
    if (!id) {
      return;
    }

    const load = async () => {
      let valid = null;

      if (isValidEmail(id)) {
        valid = id;
      } else {
        const parsed = decryptString(id);
        if (isValidEmail(parsed)) {
          valid = parsed;
        }
      }
      setParsedID(valid);
      setLoading(true);
      if (valid) {
        await loadProfiles([valid], {authMode: 'apiKey'});
      }

      setLoading(false);
    };

    load();
  }, [id]);

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  if (!parsedId || !profile?.id) {
    return (
      <div className="page-container">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <p>What you're looking for doesn't exist.</p>
        )}
      </div>
    );
  }

  return (
    <div className="page-container">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <IoMdArrowBack
            className="clickable"
            onClick={() => {
              navigate(-1);
            }}
          />
          <Header id={parsedId} />
          <div className="margin-l16 padding-top8">
            <Tab items={tabs} index={index} setIndex={setIndex} />
          </div>
          {index === 0 && (
            <RequestProfileDetail
              id={parsedId}
              profile={profile}
              loading={loading}
            />
          )}
          {index === 1 && (
            <ResourceProfileDetail
              id={parsedId}
              profile={profile}
              loading={loading}
            />
          )}
        </>
      )}
    </div>
  );
};

export const RequestProfileDetail = ({id, profile, loading}) => {
  const type = 'outer';
  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
            </div>
            <div className="grid-5">
              {/* <ConnectCard id={id} /> */}
              <div className="card">
                <h4>Requests Breakdown</h4>
                <TagsRadarChart
                  tags={profile.outer_tags || []}
                  style={{height: '200px'}}
                />
              </div>
              <BasicInfo id={id} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ResourceProfileDetail = ({
  id,
  profile,
  loading,
  isPublic = false,
}) => {
  const type = 'inner';

  return (
    <div className="">
      {loading ? (
        <SpinningIndicator />
      ) : (
        <>
          <div className="grid-container padding-top8">
            <div className="grid-7">
              <Priorities id={id} type={type} />
              {/* <Posts id={id} type={'resource'} isPublic={isPublic} /> */}
            </div>
            <div className="grid-5">
              {/* <ConnectCard id={id} /> */}
              <div className="card">
                <h4>Resources Breakdown</h4>
                <TagsRadarChart
                  tags={profile.inner_tags || []}
                  style={{height: '200px'}}
                />
              </div>

              <BasicInfo id={id} />
              {/* <Organizations id={id} /> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ConnectCard = ({id}) => {
  const {
    state: {id: current_id, profiles},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {calendar_link, first_name} = profile;

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  if (id === current_id) {
    return null;
  }

  return (
    <div>
      <div className="card">
        {loading ? (
          <SpinningIndicator />
        ) : (
          <>
            <div>
              <h4>Stay up-to-date on my priorities and projects</h4>
              <div className="flex justify-center">
                <button
                  className="create-opportunity-button"
                  onClick={() => {
                    setShow(!show);
                  }}>
                  Subscribe
                </button>
              </div>
              {current_id && calendar_link ? (
                <div className="flex-row justify-between align-center">
                  <p className="text-left text-bold">
                    User their personal link.
                  </p>
                  <a
                    href={calendar_link}
                    target="_blank"
                    rel="noopener noreferrer">
                    connect
                  </a>
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      <SubscribeModal profile={profile} active={show} setActive={setShow} />
    </div>
  );
};

export const Header = ({id, isPublic = false}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {
    state: {profiles, organizations},
  } = useAuth();

  const [popup, setPopup] = useState({on: false, message: ''});

  const profile = profiles?.[id] ?? {};

  const {organization_ids, position, status, signup_timestamp} = profile;

  const active_since = signup_timestamp
    ? formatMMDDYYYY(convertToDate(signup_timestamp * 1000))
    : null;

  const current_org = organization_ids?.length
    ? organizations?.[organization_ids[0]]
    : null;

  const pretty_name = prettyName(profile);

  return (
    <div className="flex-row align-center justify-between margin-l16">
      <div className="flex-column">
        <div className="flex-row align-center">
          <ProfileImage data={profile.profile_image} />
          <div>
            {active_since && (
              <p className="text-left text-secondary margin-h16 text-14">
                Active since {active_since}
              </p>
            )}
            <div className="flex-row align-center margin-h16 ">
              <h2>{pretty_name}</h2>
              {/* <StatusIcon status={status} /> */}
            </div>
            {position && (
              <p className="text-left text-secondary margin-h16 text-14">
                {position}
              </p>
            )}
            {current_org && (
              <p
                className={`text-left text-secondary margin-h16 text-14 ${
                  !isPublic && ' clickable'
                }`}
                onClick={() => {
                  navigate(`/feed/organizations/detail/${current_org.id}`);
                }}>
                {current_org.name}
              </p>
            )}
            <YourReferer cur_id={id} />
            {/* {curr_user && id !== curr_user && !isPublic ? (
              note ? (
                <Tooltip text={`View your notes on ${first_name}`}>
                  <div
                    className="flex-column align-center justify-center margin-h16 clickable"
                    onClick={() => {
                      navigate(`/notes/${note}`);
                    }}>
                    <RxFileText size={'25px'} />
                    <p className="text-secondary text-10">Notes</p>
                  </div>
                </Tooltip>
              ) : (
                <>
                  <Tooltip text={`View your notes on ${first_name}`}>
                    <div
                      className="flex-column align-center margin-h16 clickable"
                      onClick={async () => {
                        const now = dateToTimestamp();

                        const rolodex = {
                          id: v4(),
                          source_user: curr_user,
                          updated: now,
                          created: now,
                          first_name: first_name || null,
                          last_name: last_name || null,
                          emails: [email],
                          job_title: position || null,
                          company: null,
                          locations: [],
                          inner_tags: [],
                          outer_tags: [],
                          social_links: [],
                          notes: [],
                        };

                        await createEntry(rolodex);
                        track('rolodex_entry_created', {
                          user: curr_user,
                          rolodex,
                        });
                        navigate(`/notes/${rolodex.id}`);
                      }}>
                      <RxFilePlus size={'25px'} />
                    </div>
                    <p className="text-secondary text-10">Notes</p>
                  </Tooltip>
                </>
              )
            ) : null} */}
          </div>
        </div>
      </div>
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

export const BasicInfo = ({id}) => {
  const navigate = useNavigate();
  const {
    state: {id: user_id, profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio} = profile;
  if (!bio) {
    return null;
  }

  return (
    <div className="card">
      <h4>About</h4>
      <DisplayField header="Bio" content={bio} />
      {/* <DisplayField header="Contact" content={user_id ? email : null} /> */}
    </div>
  );
};

export const Priorities = ({id, type}) => {
  const {
    state: {profiles, organizations},
  } = useAuth();

  const profile = profiles?.[id] ?? {};
  const {email, bio, inner_tags, outer_tags} = profile;

  return (
    <div>
      <ProfileTagDisplay
        inner_tags={inner_tags}
        outer_tags={outer_tags}
        type={type}
      />
    </div>
  );
};

export const Organizations = ({id}) => {
  const {loadOrgs} = useLoader();
  const {
    state: {id: user, profiles, organizations},
  } = useAuth();

  const {organization_ids} = profiles?.[id] ?? {};

  useEffect(() => {
    loadOrgs(organization_ids, {authMode: 'apiKey'});
  }, [organization_ids]);

  return (
    <div className="card">
      <h4>Organizations</h4>
      {organization_ids?.length
        ? organization_ids.map(id => {
            return (
              <OrganizationItemPreview
                key={id}
                disabled={!user}
                item={organizations[id] || {}}
              />
            );
          })
        : null}
    </div>
  );
};

export default ProfileDetail;
