import {useFormik} from 'formik';
import React, {useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import '../../App.css';
import {PrimaryButton} from '../../components/buttons/buttons';
import {PublicMindLogoLightNoText} from '../../components/images/logos';
import {FormikTextfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';

const ForgotPassword = ({}) => {
  const [index, setIndex] = useState(0);
  const [email, setEmail] = useState(0);

  return (
    <>
      <div className="flex-column align-center">
        <PublicMindLogoLightNoText />
        <h3 className="">Forgot Password</h3>
        <p className="text-secondary padding-bottom16">
          Sorry to hear that! Type your email in below.
        </p>
      </div>{' '}
      {index === 0 && <SendCode setIndex={setIndex} setEmail={setEmail} />}
      {index === 1 && <ConfirmCode email={email} />}
    </>
  );
};

const SendCode = ({setEmail, setIndex}) => {
  const isMounted = useMounted();
  const {state, forgotPassword} = useAuth();

  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email} = values;
        const {success, error} = await forgotPassword(email);
        if (success) {
          setEmail(email);
          setIndex(1);
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <form
      className={
        mobile ? 'mobile-form pretty-form-group' : 'width-50 pretty-form-group'
      }>
      <FormikTextfield
        formik={formik}
        header="Email"
        placeholder=""
        type="email"
        name="email"
      />
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center padding-top8">
        <button
          type="button"
          className="basic-button "
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          SEND
        </button>
      </div>
    </form>
  );
};

const ConfirmCode = ({email}) => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const {state, confirmForgottenPassword} = useAuth();

  const mobile = isMobile || isTablet;

  const formik = useFormik({
    initialValues: {
      email: email || '',
      code: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      code: Yup.string().required('Code is required.'),
      password: Yup.string().max(255).required('Password is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {email, code, password} = values;
        const {success, error} = await confirmForgottenPassword({
          email,
          code,
          password,
        });
        if (success) {
          navigate(-1);
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <form
      className={
        mobile ? 'mobile-form pretty-form-group' : 'width-50 pretty-form-group'
      }>
      <FormikTextfield
        disabled={true}
        formik={formik}
        header="Email"
        placeholder=""
        type="email"
        name="email"
      />
      <FormikTextfield
        formik={formik}
        header="Verification code sent to your email"
        placeholder=""
        type="text"
        name="code"
      />
      <FormikTextfield
        formik={formik}
        header="New Password"
        placeholder=""
        type="password"
        name="password"
      />
      {formik.errors.submit && <p className="error">{formik.errors.submit}</p>}
      <div className="flex justify-center padding-top8">
        <PrimaryButton
          className="basic-button "
          type="button"
          onClick={formik.handleSubmit}
          disabled={formik.isSubmitting}>
          CONFIRM
        </PrimaryButton>
      </div>
    </form>
  );
};

export default ForgotPassword;
