import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {
  FaBuilding,
  FaCheckCircle,
  FaComments,
  FaGlobe,
  FaLayerGroup,
  FaProjectDiagram,
  FaSearch,
  FaUserPlus,
} from 'react-icons/fa';
import {FaChartGantt, FaPeopleGroup} from 'react-icons/fa6';
import {GoRss} from 'react-icons/go';
import {MdSyncLock} from 'react-icons/md';
import {RxPerson} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {track} from '../../api/analytics';
import '../../App.css';
import darkShotImage from '../../assets/images/dark-shot.png';
import lightShotImage from '../../assets/images/light-feed.png';
import constants from '../../components/constants';
import {
  PublicMindLogoDarkNoText,
  PublicMindLogoLightNoText,
} from '../../components/images/logos';
import {FormikTextfield} from '../../components/inputs/textfields';
import {useAuth} from '../../hooks/use-auth';
import {useMounted} from '../../hooks/use-mounted';
import './signin.css';
const Signin = () => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const {
    state: {auth, rerouting_url},
    signin,
    signOut,
  } = useAuth();
  const [darkMode, setTheme] = useState(false);

  useEffect(() => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    setTheme(currentTheme === 'dark');
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {email, password} = values;
        if (auth) {
          await signOut();
        }
        const response = await signin(email, password);
        const {success, error, isSignedIn, nextStep} = response;
        if (success && isMounted()) {
          if (isSignedIn) {
            navigate('/');
          } else {
            const {signInStep} = nextStep;
            if (signInStep && signInStep === 'CONFIRM_SIGN_UP') {
              navigate('/confirm', {state: {email, password}});
              return;
            }
            navigate('/signin');
          }
          track('sign_in', {email});
        }
        if (error) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: error});
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (isMounted()) {
          helpers.setStatus({success: false});
          helpers.setErrors({submit: err.message});
          helpers.setSubmitting(false);
        }
      }
    },
  });

  return (
    <div className="signin-container">
      <main className="signin-main">
        <div className="signin-hero">
          <div className="signin-hero-content">
            <span className="flex justify-center">
              {darkMode ? (
                <PublicMindLogoDarkNoText />
              ) : (
                <PublicMindLogoLightNoText />
              )}
            </span>
            <h1 className="signin-hero-title">Welcome Back</h1>
            <p className="signin-hero-description">
              Sign in to access your secure workspace for government projects.
            </p>
          </div>
          <div className="signin-form-container">
            <div className="signin-form pretty-form-group">
              <h2 className="signin-form-title padding-top8">Sign In </h2>
              <p className="signin-form-description">
                Enter your credentials to access your account.
              </p>
              <form onSubmit={formik.handleSubmit}>
                <FormikTextfield
                  formik={formik}
                  header="Email"
                  placeholder="example@1.us.gov"
                  type="email"
                  name="email"
                />
                <FormikTextfield
                  formik={formik}
                  header="Password"
                  placeholder="**********"
                  name="password"
                  type="password"
                />
                {formik.errors.submit && (
                  <p className="signin-error">{formik.errors.submit}</p>
                )}
                <div className="flex justify-center padding-top8">
                  <button
                    type="submit"
                    className="basic-button"
                    disabled={formik.isSubmitting}>
                    Sign In
                  </button>
                </div>
                <button
                  type="button"
                  className="signin-forgot-password"
                  onClick={() => navigate('/forgot-password')}>
                  Forgot Password?
                </button>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export const LandingPage = () => {
  const navigate = useNavigate();

  const version = 1.1;

  const [darkMode, setTheme] = useState(false);
  const mobile = isMobile || isTablet;

  useEffect(() => {
    const currentTheme = document.documentElement.getAttribute('data-theme');
    setTheme(currentTheme === 'dark');
  }, []);

  const Feature = ({icon, title, description}) => (
    <div className="signin-feature">
      <div className="signin-feature-icon">{icon}</div>
      <h4 className="signin-feature-title">{title}</h4>
      {description}
    </div>
  );

  const StreamlineItem = ({icon, title, description}) => (
    <div className="signin-streamline-item">
      <div className="signin-streamline-icon">{icon}</div>
      <h4 className="signin-streamline-title">{title}</h4>
      {description}
    </div>
  );

  return (
    <div className="signin-container">
      {!mobile && (
        <header className="signin-header">
          <nav className="signin-nav">
            <div className="flex-row">
              {!darkMode && <PublicMindLogoLightNoText />}
              {darkMode && <PublicMindLogoDarkNoText />}
              <h3
                // className="signin-logo"
                style={{
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 200,
                  fontSize: 18,
                  letterSpacing: 0.1,
                  paddingTop: 16,
                  paddingBottom: 16,
                }}>
                PUBLICMIND
              </h3>
            </div>

            <div className="signin-nav-links">
              {!mobile && (
                <button
                  className="signin-button signin-button-outline"
                  onClick={() => {
                    track('get_started_nav_bar_click', {version});
                    navigate(
                      '/threads/invite/e91ac7e1-9bd6-4125-8c6a-85de120874ea',
                    );
                  }}>
                  Get Started
                </button>
              )}
              {!mobile && (
                <span
                  className="flex-column align-center clickable"
                  onClick={() => {
                    track('sign_in_landing_click', {
                      version,
                    });

                    navigate('/signin');
                  }}>
                  <RxPerson size={'20px'} />
                  <p className="text-secondary text-12">Log in</p>
                </span>
              )}
            </div>
          </nav>
        </header>
      )}
      {mobile && <PublicMindLogoDarkNoText />}
      <main className="signin-main">
        <div className="signin-hero">
          <div className="signin-hero-content">
            <h1 className="signin-hero-title">
              Secure Collaboration for Government Projects
            </h1>
            <p className="signin-hero-description">
              PublicMind: Your official channel for projects at the intersection
              of government, academia, and industry
            </p>
            <div className="signin-hero-buttons">
              {!mobile && (
                <div className="button-gradient-wrapper">
                  <button
                    className="signin-button signin-button-primary"
                    onClick={() => {
                      track('try_for_free_landing_click_hero', {
                        version,
                      });
                      navigate(
                        '/threads/invite/e91ac7e1-9bd6-4125-8c6a-85de120874ea',
                      );
                    }}>
                    Try for{' '}
                    <strong className="landing-page-critical-color">
                      Free
                    </strong>
                  </button>
                </div>
              )}
              {mobile && (
                <div className="button-gradient-wrapper">
                  <button
                    className="signin-button signin-button-secondary"
                    onClick={() => {
                      track('schedule_demo_mobile_landing_click_hero', {
                        version,
                      });
                      window.location.href =
                        "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
                    }}>
                    Schedule a Demo
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="signin-hero-image">
            <div className="platform-preview-wrapper">
              <img
                src={darkMode ? darkShotImage : lightShotImage}
                alt="Platform Preview"
              />
            </div>
            <h1 className="signin-hero-title-secondary">
              A Secure, Easy-to-Use Workspace
            </h1>
            <p className="signin-hero-description">
              From roles-based permissions per project to project
              discoverability controls for added security, you can centralize
              your people and projects. This allows easy access for authorized
              parties, regardless of their organization's systems.
            </p>
            <p className="signin-hero-description">
              You can also break projects into smaller chunks, share files, and
              much more.
            </p>
          </div>
          {!mobile && (
            <div className="button-gradient-wrapper">
              <button
                className="signin-button signin-button-secondary"
                onClick={() => {
                  track('schedule_demo_landing_click', {
                    version,
                  });
                  window.location.href =
                    "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
                }}>
                Schedule a{' '}
                <strong className="landing-page-critical-color">Demo</strong>
              </button>
            </div>
          )}
        </div>

        <section className="signin-features">
          <h3 className="signin-section-title">
            <strong className="landing-page-critical-color">Enhance</strong>{' '}
            Coordination and Effectiveness in Your Projects
          </h3>
          <div className="signin-features-grid">
            <Feature
              icon={<MdSyncLock />}
              title="Secure Cross-Agency Coordination"
              description={
                <p className="signin-feature-description">
                  Protect sensitive data with{' '}
                  <strong className="landing-page-critical-color">
                    role-based access controls
                  </strong>{' '}
                  and enable seamless communication{' '}
                  <strong className="landing-page-critical-color">
                    across departments and external partners.
                  </strong>
                </p>
              }
            />
            <Feature
              icon={<FaLayerGroup />}
              title="Centralized Information Management"
              description={
                <p className="signin-feature-description">
                  Create a{' '}
                  <strong className="landing-page-critical-color">
                    single source of truth
                  </strong>{' '}
                  for all project documentation. Maintain{' '}
                  <strong className="landing-page-critical-color">
                    clear audit trails
                  </strong>{' '}
                  for decision-making processes.
                </p>
              }
            />
            <Feature
              icon={<FaPeopleGroup />}
              title="Streamlined Stakeholder Engagement"
              description={
                <p className="signin-feature-description">
                  Facilitate{' '}
                  <strong className="landing-page-critical-color">
                    easy onboarding
                  </strong>{' '}
                  for diverse stakeholders and enable{' '}
                  <strong className="landing-page-critical-color">
                    real-time updates
                  </strong>{' '}
                  and notifications to keep all parties informed.
                </p>
              }
            />
          </div>
        </section>

        <section className="signin-streamline">
          <h3 className="signin-section-title">
            {' '}
            <strong className="landing-page-critical-color-light">
              Maximize
            </strong>{' '}
            Project Success
          </h3>
          <div className="signin-streamline-grid">
            <StreamlineItem
              icon={<GoRss className="landing-page-critical-color-light" />}
              title="Host or Discover: Projects, Events, and More"
              description={
                <p className="signin-streamline-description">
                  <strong className="landing-page-critical-color-light">
                    Centralize project information{' '}
                  </strong>{' '}
                  and updates across government, academia, and industry. Gain
                  real-time insights and{' '}
                  <strong className="landing-page-critical-color-light">
                    eliminate information silos
                  </strong>{' '}
                  for seamless collaboration.
                </p>
              }
            />
            <StreamlineItem
              icon={
                <FaUserPlus className="landing-page-critical-color-light" />
              }
              title="Invite All Stakeholders"
              description={
                <p className="signin-streamline-description">
                  <strong className="landing-page-critical-color-light">
                    Securely onboard and manage
                  </strong>{' '}
                  authorized personnel with ease. Ensure data integrity and
                  controlled access,{' '}
                  <strong className="landing-page-critical-color-light">
                    fostering trust
                  </strong>{' '}
                  among diverse project participants.
                </p>
              }
            />
            <StreamlineItem
              icon={
                <FaComments className="landing-page-critical-color-light" />
              }
              title="Streamlined and Auditable Communication"
              description={
                <p className="signin-streamline-description">
                  <strong className="landing-page-critical-color-light">
                    Replace endless email chains
                  </strong>{' '}
                  with organized, accessible discussions. Boost productivity and
                  decision-making through clear,{' '}
                  <strong className="landing-page-critical-color-light">
                    centralized project communications.
                  </strong>
                </p>
              }
            />
          </div>
        </section>
        <PublicMindDifferentiators
          version={version}
          navigate={navigate}
          mobile={mobile}
        />
        <section className="signin-final">
          <h3 className="signin-final-title">Less Meetings, More Results</h3>
          <p className="text-secondary text-center signin-hero-description">
            Bring people together. Meet less. Achieve more.
          </p>

          <div className="signin-final-buttons">
            {!mobile && (
              <div className="button-gradient-wrapper">
                <button
                  className="signin-button signin-button-primary"
                  onClick={() => {
                    track('try_for_free_final_cta_landing_click', {
                      version,
                    });
                    navigate(
                      '/threads/invite/e91ac7e1-9bd6-4125-8c6a-85de120874ea',
                    );
                  }}>
                  Try for{' '}
                  <strong className="landing-page-critical-color">Free</strong>
                </button>
              </div>
            )}
            {mobile && (
              <div className="button-gradient-wrapper">
                <button
                  className="signin-button signin-button-secondary"
                  onClick={() => {
                    track('schedule_demo_mobile_final_cta_landing_click', {
                      version,
                    });
                    window.location.href =
                      "mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance.";
                  }}>
                  Schedule a{' '}
                  <strong className="landing-page-critical-color">Demo</strong>
                </button>
              </div>
            )}
          </div>
        </section>
      </main>
      <PublicFooter />
    </div>
  );
};
const PublicFooter = () => {
  return (
    <footer className="public-footer">
      <div className="public-footer-content">
        <div className="public-footer-main">
          <div className="public-footer-logo">
            <PublicMindLogoDarkNoText />
            <h3 className="public-footer-logo-text">PUBLICMIND</h3>
          </div>
          <p className="public-footer-description">
            Empowering secure collaboration for government initiatives.
          </p>
        </div>
        <div className="public-footer-links-container">
          {/* <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Company</h4>
            <ul className="public-footer-links">
              <li>
                <a href="#" className="public-footer-link">
                  About
                </a>
              </li>
              <li>
                <a href="#" className="public-footer-link">
                  Features
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=PublicMind%20Inquiry&body=Hello,%20I'm%20looking%20to%20learn%20more%20about%20how%20PublicMind%20can%20drive%20better%20efficiency%20for%20me%20and%20my%20team.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20are%20having%20trouble%20with%20[problem].%0A%0AThank%20you%20in%20advance."
                  className="public-footer-link">
                  Contact
                </a>
              </li>
            </ul>
          </div> */}
          <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Resources</h4>
            <ul className="public-footer-links">
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=Documentation%20Access%20Request&body=Hello,%20we%20would%20like%20to%20request%20access%20to%20the%20PublicMind%20API.%0A%0AOur%20team%20is%20on%20the%20[government/academic/industry]%20side%20and%20we%20are%20looking%20to%20integrate%20PublicMind's%20capabilities%20for%20[describe%20use%20case].%0A%0AThank%20you%20for%20considering%20our%20request."
                  className="public-footer-link">
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=API%20Access%20Request&body=Hello,%20we%20would%20like%20to%20request%20access%20to%20the%20PublicMind%20API.%0A%0AOur%20team%20is%20on%20the%20[government/academic/industry]%20side%20and%20we%20are%20looking%20to%20integrate%20PublicMind's%20capabilities%20for%20[describe%20use%20case].%0A%0AThank%20you%20for%20considering%20our%20request."
                  className="public-footer-link">
                  API
                </a>
              </li>
              <li>
                <a
                  href="mailto:help@publicmind.ai?subject=Support%20Request&body=Hello,%20I'm%20reaching%20out%20for%20support%20regarding%20an%20issue%20we%20are%20facing%20with%20PublicMind.%0A%0AWe%20are%20on%20the%20[government/academic/industry]%20side%20and%20need%20assistance%20with%20[describe%20the%20issue].%0A%0AThank%20you%20for%20your%20help."
                  className="public-footer-link">
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div className="public-footer-links-column">
            <h4 className="public-footer-heading">Legal</h4>
            <ul className="public-footer-links">
              <li>
                <a
                  href={constants.privacy_policy}
                  className="public-footer-link">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={constants.terms_of_service}
                  className="public-footer-link">
                  Terms of Service
                </a>
              </li>
              {/* <li>
                <a href="#" className="public-footer-link">
                  Cookie Policy
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <div className="public-footer-bottom">
        <p className="public-footer-copyright">
          © 2024 PublicMind. All rights reserved.
        </p>
        <div className="public-footer-social">
          {/* <a href="#" className="public-footer-social-link">
            <FaTwitter />
          </a>
          <a href="#" className="public-footer-social-link">
            <FaLinkedin />
          </a>
          <a href="#" className="public-footer-social-link">
            <FaGithub />
          </a> */}
        </div>
      </div>
    </footer>
  );
};

const DifferentiatorItem = ({icon, title, description}) => (
  <div className="differentiator-item">
    <div className="differentiator-icon">{icon}</div>
    <h4 className="differentiator-title">{title}</h4>
    <p className="differentiator-description">{description}</p>
  </div>
);

const PublicMindDifferentiators = ({version, navigate, mobile}) => (
  <section className="publicmind-differentiators">
    <h3 className="signin-section-title">
      How <strong className="landing-page-critical-color">PublicMind</strong>{' '}
      Stands Apart
    </h3>
    <p className="differentiators-intro">
      Unlike general-purpose collaboration tools like Slack, Basecamp, or
      Monday.com, PublicMind is tailored specifically for government, academic,
      and industry partnerships:
    </p>
    <div className="differentiators-grid">
      <DifferentiatorItem
        icon={<FaCheckCircle className="landing-page-critical-color" />}
        title="Official Project-Centric Communication"
        description="While Slack focuses on topic-based channels, PublicMind is built around projects, serving as the authoritative platform for official project communications across organizations."
      />
      <DifferentiatorItem
        icon={<FaGlobe className="landing-page-critical-color" />}
        title="Seamless External Collaboration"
        description="Unlike Basecamp or Monday.com, PublicMind allows easy collaboration with external parties, regardless of their organization's internal systems, breaking down inter-organizational barriers."
      />
      <DifferentiatorItem
        icon={<FaSearch className="landing-page-critical-color" />}
        title="Project Discovery and Networking"
        description="Unique to PublicMind, users can discover relevant projects, request to join, or make their own projects discoverable, fostering cross-sector collaboration that's impossible with closed systems like Slack."
      />
      <DifferentiatorItem
        icon={<FaChartGantt className="landing-page-critical-color" />}
        title="Economic and Workforce Development Catalyst"
        description="PublicMind accelerates economic growth by streamlining public-private partnerships and facilitating rapid implementation of workforce development initiatives."
      />
      <DifferentiatorItem
        icon={<FaBuilding className="landing-page-critical-color" />}
        title="Government-Focused Solution"
        description="Unlike one-size-fits-all tools, PublicMind is specifically designed to serve government clients and their unique needs, workflows, and requirements."
      />
      <DifferentiatorItem
        icon={<FaProjectDiagram className="landing-page-critical-color" />}
        title="Cross-Sector Ecosystem"
        description="PublicMind creates a connected ecosystem across government, academia, and industry, enabling collaborations and insights that are not possible with siloed tools like Slack or Basecamp."
      />
    </div>
  </section>
);

export default Signin;
