// ThreadButtons.js
import React, {useEffect, useRef, useState} from 'react';
import {CgMenuGridO} from 'react-icons/cg';
import {GoRss} from 'react-icons/go';
import {RxActivityLog, RxCheckbox, RxClock, RxHome} from 'react-icons/rx';
import {VscMention} from 'react-icons/vsc';
import {useNavigate, useParams} from 'react-router-dom';
import {dateToTimestamp} from '../../../api/dates';
import './ThreadButtons.css';

export const ThreadButtons = ({
  feedCount = 0,
  activityCount = 0,
  tasksCount = 0,
  mentionsCount = 0,
  draftsCount = 0,
}) => {
  const {tab} = useParams();
  const [cur, setCur] = useState(tab);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({top: 0, left: 0});
  const menuContainerRef = useRef(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (tab) setCur(tab);
    if (!tab) setCur('thread-detail');
  }, [tab]);

  useEffect(() => {
    if (isMenuOpen && menuContainerRef.current && menuRef.current) {
      const containerRect = menuContainerRef.current.getBoundingClientRect();
      const menuRect = menuRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let top = containerRect.bottom;
      let left = containerRect.left;

      if (left + menuRect.width > viewportWidth) {
        left = viewportWidth - menuRect.width - 10;
      }

      if (top + menuRect.height > viewportHeight) {
        top = containerRect.top - menuRect.height;
      }

      setMenuPosition({top, left});
    }
  }, [isMenuOpen]);

  const renderButton = (icon, title, indexName, count) => (
    <button
      key={title}
      className={`sidebar-action-button ${cur === indexName ? 'active' : ''}`}
      onClick={() => {
        if (indexName === 'home') {
          navigate(`/threads`);
        } else {
          navigate(`/threads`, {
            state: {tab: indexName, timestamp: dateToTimestamp()},
          });
        }
        setIsMenuOpen(false);
      }}
      aria-label={title}>
      <div className="button-content">
        {icon}
        <span className="button-title">{title}</span>
        {count > 0 && <span className="notification-count">{count}</span>}
      </div>
    </button>
  );

  const menuItems = [
    {
      icon: <RxHome />,
      title: 'Home',
      indexName: 'home',
      count: mentionsCount,
    },
    {
      icon: <GoRss />,
      title: 'Discover',
      indexName: 'feed',
      count: mentionsCount,
    },
    {
      icon: <VscMention />,
      title: 'Mentions',
      indexName: 'mentions',
      count: mentionsCount,
    },
    {
      icon: <RxActivityLog />,
      title: 'Activity',
      indexName: 'activity',
      count: activityCount,
    },
    // {
    //   icon: <RxSewingPinFilled />,
    //   title: 'Milestones',
    //   indexName: 'milestone',
    //   count: activityCount,
    // },
    // {
    //   icon: <MdLabelImportantOutline />,
    //   title: 'Decision',
    //   indexName: 'decision_point',
    //   count: activityCount,
    // },
    {
      icon: <RxCheckbox />,
      title: 'Tasks',
      indexName: 'tasks',
      count: tasksCount,
    },
    // {
    //   icon: <RxQuestionMarkCircled />,
    //   title: 'Questions',
    //   indexName: 'question',
    //   count: activityCount,
    // },
    // {icon: <BiPoll />, title: 'Polls', indexName: 'poll', count: activityCount},
    {
      icon: <RxClock />,
      title: 'Scheduled',
      indexName: 'drafts',
      count: draftsCount,
    },
  ];

  return (
    <div className="sidebar-actions">
      <div
        className="menu-container"
        ref={menuContainerRef}
        onMouseEnter={() => setIsMenuOpen(true)}
        onMouseLeave={() => setIsMenuOpen(false)}>
        <button
          className={`sidebar-action-button ${isMenuOpen ? 'active' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          aria-label="Menu">
          <div className="button-content">
            <CgMenuGridO size={'20px'} />
          </div>
        </button>
        {isMenuOpen && (
          <div
            className="grid-submenu"
            ref={menuRef}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              position: 'fixed',
            }}>
            {menuItems.map(item =>
              renderButton(item.icon, item.title, item.indexName, item.count),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
