import React, {useEffect, useMemo, useState} from 'react';
import {GoGitBranch} from 'react-icons/go';
import {RxChevronDown, RxChevronUp, RxDotFilled} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import '../ThreadStyles.css';

// DISPLAYS THE LEFT THREAD SIDEBAR
export const ThreadList = ({current, setCurrent, setIndex}) => {
  const navigate = useNavigate();
  const {
    state: {id: userId},
  } = useAuth();
  const {
    state: {threads, thread_ids, thread_subs},
  } = useThreads();
  const [collapsedSections, setCollapsedSections] = useState({
    pinned: false,
    unread: false,
    other: false,
  });

  const toggleSection = section => {
    setCollapsedSections(prev => ({...prev, [section]: !prev[section]}));
  };

  const groupedThreads = useMemo(() => {
    const groups = {
      pinned: [],
      unread: [],
      other: [],
    };

    thread_ids.forEach(threadId => {
      const thread = threads[threadId];
      const sub = thread_subs?.[threadId] ?? null;

      // Check if thread is pinned and is a sub-thread
      if (sub?.priority) {
        groups.pinned.push(threadId);
        return; // Skip further categorization for pinned sub-threads
      }

      // If it's a sub-thread but not pinned, don't include it
      if (thread.type === 'sub-thread') {
        return;
      }

      // Check if thread is unread
      const isUnread =
        userId !== thread.last_sender &&
        (!sub?.last_read || sub?.last_read < thread.updated);

      if (isUnread) {
        groups.unread.push(threadId);
      } else {
        groups.other.push(threadId);
      }
    });

    // Sort threads within each group
    const sortThreads = (a, b) => threads[b].updated - threads[a].updated;
    groups.pinned.sort(sortThreads);
    groups.unread.sort(sortThreads);
    groups.other.sort(sortThreads);

    return groups;
  }, [thread_ids, threads, thread_subs, userId]);

  useEffect(() => {
    if (current) {
      for (const [section, threadIds] of Object.entries(groupedThreads)) {
        if (threadIds.includes(current)) {
          setCollapsedSections(prev => ({...prev, [section]: false}));
          break;
        }
      }
    }
  }, [current, groupedThreads]);

  const renderSection = (title, threadIds, sectionKey) => {
    if (threadIds.length === 0) return null;
    return (
      <div className="thread-group">
        <h5
          className="thread-group__title clickable"
          onClick={() => toggleSection(sectionKey)}>
          {title}{' '}
          {collapsedSections[sectionKey] ? <RxChevronDown /> : <RxChevronUp />}
        </h5>
        {!collapsedSections[sectionKey] && (
          <div className="">
            {threadIds.map(threadId => (
              <ThreadItem
                key={threadId}
                item={threads[threadId]}
                isActive={current === threadId}
                onClick={() => {
                  setIndex('thread-detail');
                  setCurrent(threadId);
                  navigate(`/threads/${threadId}`);
                  track('clicked_thread_sidebar', {
                    thread_group: sectionKey,
                    thread_name: threads?.[threadId] || 'Unknown',
                    thread_id: threadId,
                  });
                }}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="">
      {renderSection('Pinned Threads', groupedThreads.pinned, 'pinned')}
      {renderSection('Unread Threads', groupedThreads.unread, 'unread')}
      {renderSection('Threads', groupedThreads.other, 'other')}
    </div>
  );
};

const ThreadItem = ({item, onClick, isActive}) => {
  const {id, title, content, updated, last_sender, logo, custom_logo, type} =
    item;

  const {
    state: {id: user_id},
  } = useAuth();
  const {
    state: {thread_subs},
  } = useThreads();

  const sub = thread_subs?.[id] ?? null;

  const new_content =
    user_id !== last_sender && (!sub?.last_read || sub?.last_read < updated);

  return (
    <div
      className={`thread-item ${isActive ? 'active' : ''} ${
        type === 'thread' ? 'sub-thread' : ''
      } justify-between`}
      onClick={onClick}>
      <span
        className={`flex-row align-center ${new_content ? 'text-bold' : ''}`}>
        {type === 'sub-thread' ? (
          <GoGitBranch className="text-secondary" size={'14px'} />
        ) : (
          ''
        )}
        {custom_logo && (
          <img
            src={custom_logo?.url}
            alt="Logo"
            style={{
              height: '16px',
              maxWidth: '32px',
              width: 'auto',
              marginRight: '2px',
            }}
          />
        )}
        <p className="thread-item__title">
          {logo} {title}
          {type === 'sub-thread' && (
            <span className="sub-thread-indicator"> (Breakout)</span>
          )}
          {type === 'direct' && (
            <span className="sub-thread-indicator"> (Direct)</span>
          )}
        </p>
      </span>
      {new_content ? <RxDotFilled color="red" /> : null}
    </div>
  );
};
