import React, {useEffect} from 'react';
import {RxChevronRight, RxCross2, RxMagnifyingGlass} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {timeSince} from '../../../api/dates';
import {
  FileViewerSimple,
  ProfileImage,
} from '../../../components/inputs/uploader';
import MagicMarkdown from '../../../components/markdown/magic-markdown';
import {useAuth} from '../../../hooks/use-auth';
import useCrypto from '../../../hooks/use-crypto';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import './ThreadSearch.css';

const ThreadMentionItem = ({thread, onClick}) => {
  const {title, logo, content} = thread;
  return (
    <div className="thread-search-grid-item" onClick={onClick}>
      <div className="thread-search-grid-item-header">
        <span className="thread-search-grid-item-logo">{logo}</span>
        <p className={`text-bold ${logo ? 'padding-left8' : ''}`}>{title}</p>
      </div>
      <p className="thread-search-grid-item-content">
        {content?.slice(0, 100) + '...'}
      </p>
    </div>
  );
};

const PersonMentionItem = ({person, onClick}) => {
  const {prettyName, prettyPosition} = useStringFormatter();

  return (
    <div className="thread-mention-item" onClick={onClick}>
      <div className="thread-mention-header">
        <ProfileImage
          data={person.profile_image}
          style={{height: '40px', width: '40px'}}
        />
        <div className="mention-info">
          <p className="text-bold">{prettyName(person)}</p>
          <p className="text-secondary">{prettyPosition(person)}</p>
        </div>
      </div>
    </div>
  );
};

const ThreadFileLinkMentionItem = ({item, thread, onClick}) => {
  const {content, created, media, owner_id} = item;
  const {title: threadTitle, logo} = thread;
  const {loadProfiles} = useLoader();
  const {prettyName} = useStringFormatter();
  const {
    state: {profiles},
  } = useAuth();

  const user = profiles?.[owner_id] ?? {};
  const {profile_image} = user;

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, loadProfiles]);

  const isFile = media && media.length > 0;

  const getPreview = () => {
    if (isFile) {
      return <FileViewerSimple files={media} showPreview={true} />;
    }
    return null;
  };

  return (
    <div className="mention-item">
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{height: '40px', width: '40px'}}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {logo} {threadTitle}
          </h3>
          <p className="mention-time">{timeSince(created)}</p>
        </div>
      </div>
      <div className="mention-content">
        <div className="mention-preview">{getPreview()}</div>
      </div>
      <div className="mention-footer" onClick={onClick}>
        <span className="mention-view">View in thread</span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
};

const SearchMentionItem = ({item, thread, onClick}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();
  const {loadProfiles} = useLoader();
  const {
    state: {profiles},
  } = useAuth();

  const {owner_id, content, created, media} = item || {};
  const user = profiles?.[owner_id] ?? {};
  const profile_image = user?.profile_image;
  const {title: threadTitle, logo} = thread || {};

  useEffect(() => {
    if (owner_id) {
      loadProfiles([owner_id]);
    }
  }, [owner_id, loadProfiles]);

  return (
    <div className="mention-item">
      <div className="mention-header">
        <ProfileImage
          data={profile_image}
          style={{height: '40px', width: '40px'}}
          onClick={() => {
            navigate(`/feed/profiles/detail/${owner_id}`);
          }}
        />
        <div className="mention-info">
          <h3 className="mention-thread-title">
            {prettyName(user)} <span className="text-secondary">from</span>{' '}
            {logo} {threadTitle}
          </h3>
          <p className="mention-time">{timeSince(created)}</p>
        </div>
      </div>
      <div className="content-body">
        <MagicMarkdown content={content} />
      </div>
      {media?.length > 0 && <FileViewerSimple files={media} />}
      <div className="mention-footer">
        <span className="mention-view" onClick={onClick}>
          View thread
        </span>
        <RxChevronRight className="mention-arrow" />
      </div>
    </div>
  );
};

const ThreadSearch = ({
  onClose,
  searchResults,
  search,
  handleSearchChange,
  searchInputRef,
}) => {
  const navigate = useNavigate();
  const {encryptString} = useCrypto();

  const {prettyName} = useStringFormatter();
  const {
    state: {thread_content, threads},
  } = useThreads();
  const {
    state: {profiles},
  } = useAuth();

  const {contentIds, threadIds, fileLinkMentionIds, peopleIds} = searchResults;
  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);
  return (
    <div className="thread-search-container overlay">
      <div className="thread-search-header">
        <div />
        <div className="search-input-container">
          <RxMagnifyingGlass className="search-icon" />
          <input
            ref={searchInputRef}
            type="text"
            className="search-input"
            placeholder="Search for threads, posts, files, people..."
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        <RxCross2 onClick={onClose} className="clickable" />
      </div>
      <div className="thread-search-results">
        {threadIds.length === 0 &&
          contentIds.length === 0 &&
          fileLinkMentionIds.length === 0 &&
          peopleIds.length === 0 && (
            <div className="thread-search-empty">
              <RxMagnifyingGlass className="thread-search-empty-icon" />
              <p>{search?.length > 0 ? 'No results' : 'Find anything'}</p>
              <p>You can find threads, people, files, or specific updates.</p>
            </div>
          )}
        {threadIds.length > 0 && (
          <div className="thread-search-grid">
            {threadIds.map(id => (
              <ThreadMentionItem
                key={id}
                thread={threads[id]}
                onClick={() => {
                  navigate(`/threads/${id}`);
                  onClose();
                }}
              />
            ))}
          </div>
        )}
        {peopleIds.length > 0 && (
          <h4 className="text-secondary padding-bottom8">People</h4>
        )}
        {peopleIds.length > 0 && (
          <div className="thread-search-grid">
            {peopleIds.map(id => (
              <PersonMentionItem
                key={id}
                person={profiles[id]}
                onClick={() => {
                  const parsed = encryptString(id);
                  navigate(`/feed/profiles/detail/${parsed}`);
                  onClose();
                }}
              />
            ))}
          </div>
        )}
        {fileLinkMentionIds.length > 0 && (
          <h4 className="text-secondary padding-bottom8">Files</h4>
        )}
        {fileLinkMentionIds.map(id => (
          <ThreadFileLinkMentionItem
            key={id}
            item={thread_content[id]}
            thread={threads[thread_content[id].thread_id]}
            onClick={() => {
              navigate(`/threads/${thread_content[id].thread_id}/${id}`);
              onClose();
            }}
          />
        ))}
        {contentIds.length > 0 && (
          <h4 className="text-secondary padding-bottom8">Posts & Comments</h4>
        )}
        {contentIds.map(id => (
          <SearchMentionItem
            key={id}
            item={thread_content[id]}
            thread={threads[thread_content[id].thread_id]}
            onClick={() => {
              navigate(`/threads/${thread_content[id].thread_id}/${id}`);
              onClose();
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ThreadSearch;
