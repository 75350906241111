import React, {lazy, Suspense, useEffect, useState} from 'react';
import {RxInfoCircled} from 'react-icons/rx';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {track} from '../../../api/analytics';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {TooltipRight} from '../../../components/tooltip/tooltip';
import {useAuth} from '../../../hooks/use-auth';
import useLoader from '../../../hooks/use-loader';
import {useThreads} from '../../../hooks/use-threads';
import {ThreadList} from '../components/thread-list';
import '../ThreadStyles.css';

const ContentDrafts = lazy(() => import('../overview-tabs/content-drafts'));
const ThreadFeed = lazy(() => import('../overview-tabs/thread-feed'));
const TasksTab = lazy(() => import('../overview-tabs/tasks'));
const ChronologicalContent = lazy(() =>
  import('../overview-tabs/chronological-content'),
);
const ThreadDetail = lazy(() => import('../overview-tabs/thread-detail'));
const Mentions = lazy(() => import('../overview-tabs/mentions'));

const ThreadsOverview = () => {
  const {thread_id, content_id} = useParams();
  const {state} = useLocation();

  const {initThreads} = useLoader();
  const navigate = useNavigate();

  const {
    state: {id: userId, groups},
  } = useAuth();
  const {
    state: {thread_ids, threads, subscriptions},
    attachThreadContentListener,
  } = useThreads();

  const [current, setCurrent] = useState(thread_id ? thread_id : null);
  const [focusedContent, setFocusedContent] = useState(
    content_id ? content_id : null,
  );

  const [index, setIndex] = useState(thread_id ? 'thread-detail' : 'feed');
  const [loading, setLoading] = useState(false);

  const [TOTAL_PAID_THREADS, setPaidThreads] = useState(null);

  // CALC PAID THREAD COUNT
  useEffect(() => {
    if (threads && thread_ids) {
      const ownedThreadsCount = thread_ids.filter(threadId => {
        const thread = threads[threadId];
        return (
          thread.permissions.find(p => p.user_id === userId)?.role === 'owner'
        );
      }).length;
      setPaidThreads(ownedThreadsCount);
    }
  }, [threads, thread_ids, userId]);

  //GET THREADS AND SUBs
  useEffect(() => {
    initThreads(setLoading, data => {
      if (!thread_id && index !== 'feed') {
        setCurrent(data);
      }
    });
  }, []);

  useEffect(() => {
    if (thread_id) {
      track('thread_viewed', {
        thread_id,
        thread_title: threads[thread_id]?.title,
      });
      setIndex('thread-detail');
      setCurrent(thread_id);
    }
  }, [thread_id]);

  useEffect(() => {
    if (content_id) {
      setIndex('thread-detail');
      setFocusedContent(content_id);
    }
  }, [content_id]);

  useEffect(() => {
    if (state?.tab) {
      setIndex(state?.tab);
    }
  }, [state?.tab, state?.timestamp]);

  // ATTACH LISTENERS
  useEffect(() => {
    if (!thread_ids?.length) {
      return;
    }

    thread_ids.forEach(thread_id => {
      if (subscriptions[thread_id] === undefined) {
        attachThreadContentListener(
          {filter: {thread_id: {eq: thread_id}, status: {eq: 'active'}}},
          thread_id,
          userId,
        );
      }
    });
  }, [thread_ids]);

  useEffect(() => {
    if (index !== 'thread-detail') {
      setCurrent(null);
    }
  }, [index]);

  const isProUser = () => {
    return (
      userId === 'jack@publicmind.ai' ||
      userId === 'charlie@publicmind.ai' ||
      userId === 'kirtan@publicmind.ai' ||
      userId === 'shsimmons@dew.sc.gov' ||
      userId === 'andy.bernardin@sc.edu'
    );
  };

  return (
    <div className="page-container">
      <div className="threads-overview">
        <div className="threads-container">
          <div className="threads-list">
            <div className="threads-header">
              <TooltipRight
                text={
                  'Threads are secure workspaces for you and your partners'
                }>
                <span className="flex-row">
                  <h4>Home</h4>
                  <RxInfoCircled />
                </span>
              </TooltipRight>

              <button
                className="add-thread-button"
                disabled={!isProUser && TOTAL_PAID_THREADS >= 2}
                onClick={() => navigate('/threads/create')}>
                <TooltipRight
                  text={
                    isProUser()
                      ? `${TOTAL_PAID_THREADS} out of Unlimited Threads Used`
                      : `${TOTAL_PAID_THREADS} out of 2 Free Threads Used`
                  }>
                  <p className=" text-10">New Thread</p>
                </TooltipRight>
              </button>
            </div>

            <div className="thread-items">
              {loading ? (
                <SpinningIndicator />
              ) : (
                <ThreadList
                  current={current}
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                />
              )}
            </div>
          </div>

          <div className="thread-detail">
            <Suspense fallback={<SpinningIndicator />}>
              {index === 'thread-detail' && (
                <ThreadDetail
                  id={current}
                  focused_content={focusedContent}
                  setFocusedContent={setFocusedContent}
                  setCurrent={setCurrent}
                />
              )}
              {index === 'feed' && (
                <ThreadFeed
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setFocusedContent}
                />
              )}
              {index === 'activity' && (
                <ChronologicalContent
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setFocusedContent}
                />
              )}
              {index === 'tasks' && (
                <TasksTab
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setFocusedContent}
                />
              )}
              {index === 'mentions' && (
                <Mentions
                  setCurrent={setCurrent}
                  setIndex={setIndex}
                  setContent={setFocusedContent}
                />
              )}
              {index === 'drafts' && (
                <ContentDrafts setIndex={setIndex} setCurrent={setCurrent} />
              )}
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreadsOverview;
