import React, {useEffect, useRef} from 'react';
import {isMobile, isTablet} from 'react-device-detect';
import {RiCloseFill} from 'react-icons/ri';
import './styles.css';

export const Modal = ({active, setActive, children, disabled_dismiss}) => {
  if (active) {
    return (
      <ModalContent
        active={active}
        setActive={setActive}
        children={children}
        disabled_dismiss={disabled_dismiss}
      />
    );
  }

  return null;
};

const ModalContent = ({active, setActive, children, disabled_dismiss}) => {
  // AUTO CLOSE MODAL
  const modalRef = useRef(null); // Create a ref for this instance
  const mobile = isMobile || isTablet;

  useEffect(() => {
    function handleClickOutside(event) {
      if (disabled_dismiss) {
        return;
      }
      if (modalRef.current && event.target === modalRef.current) {
        setActive(false);
      }
    }
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [setActive, disabled_dismiss]);

  const handleClose = () => {
    if (!disabled_dismiss) {
      setActive(false);
    }
  };

  return (
    <div ref={modalRef} className={`modal ${active && 'show-modal'}`}>
      <div
        className={`modal-content`}
        style={{
          width: mobile ? '90%' : '50%',
          maxHeight: mobile ? '70vh' : '80vh',
          position: 'relative',
          boxShadow: '0 10px 25px rgba(0, 0, 0, 0.1)',
          border: '1px solid var(--primary-button)',
        }}>
        {!disabled_dismiss && (
          <RiCloseFill
            onClick={handleClose}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              cursor: 'pointer',
              fontSize: '20px',
            }}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export const SimpleDisplayModal = ({
  active,
  setActive,
  children,
  disabled_dismiss,
}) => {
  if (active) {
    return (
      <SimpleDisplayModalContent
        active={active}
        setActive={setActive}
        children={children}
        disabled_dismiss={disabled_dismiss}
      />
    );
  }
  return null;
};

const SimpleDisplayModalContent = ({
  active,
  setActive,
  children,
  disabled_dismiss,
}) => {
  const modalRef = useRef(null);
  const mobile = isMobile || isTablet;

  useEffect(() => {
    function handleClickOutside(event) {
      if (disabled_dismiss) {
        return;
      }
      if (modalRef.current && event.target === modalRef.current) {
        setActive(false);
      }
    }
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [setActive, disabled_dismiss]);

  const handleClose = () => {
    if (!disabled_dismiss) {
      setActive(false);
    }
  };

  return (
    <div
      ref={modalRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}>
      <div
        style={{
          // backgroundColor: '',
          padding: '20px',
          borderRadius: '8px',
          width: mobile ? '90%' : '',
          maxHeight: mobile ? '70vh' : '80vh',
          overflow: 'auto',
          position: 'relative',
        }}>
        {children}
      </div>
    </div>
  );
};
