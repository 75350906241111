/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessageChain = /* GraphQL */ `
  mutation CreateMessageChain(
    $input: CreateMessageChainInput!
    $condition: ModelMessageChainConditionInput
  ) {
    createMessageChain(input: $input, condition: $condition) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessageChain = /* GraphQL */ `
  mutation UpdateMessageChain(
    $input: UpdateMessageChainInput!
    $condition: ModelMessageChainConditionInput
  ) {
    updateMessageChain(input: $input, condition: $condition) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessageChain = /* GraphQL */ `
  mutation DeleteMessageChain(
    $input: DeleteMessageChainInput!
    $condition: ModelMessageChainConditionInput
  ) {
    deleteMessageChain(input: $input, condition: $condition) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWaitlist = /* GraphQL */ `
  mutation CreateWaitlist(
    $input: CreateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    createWaitlist(input: $input, condition: $condition) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateWaitlist = /* GraphQL */ `
  mutation UpdateWaitlist(
    $input: UpdateWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    updateWaitlist(input: $input, condition: $condition) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteWaitlist = /* GraphQL */ `
  mutation DeleteWaitlist(
    $input: DeleteWaitlistInput!
    $condition: ModelWaitlistConditionInput
  ) {
    deleteWaitlist(input: $input, condition: $condition) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPageView = /* GraphQL */ `
  mutation CreatePageView(
    $input: CreatePageViewInput!
    $condition: ModelPageViewConditionInput
  ) {
    createPageView(input: $input, condition: $condition) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePageView = /* GraphQL */ `
  mutation UpdatePageView(
    $input: UpdatePageViewInput!
    $condition: ModelPageViewConditionInput
  ) {
    updatePageView(input: $input, condition: $condition) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePageView = /* GraphQL */ `
  mutation DeletePageView(
    $input: DeletePageViewInput!
    $condition: ModelPageViewConditionInput
  ) {
    deletePageView(input: $input, condition: $condition) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReferral = /* GraphQL */ `
  mutation CreateReferral(
    $input: CreateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    createReferral(input: $input, condition: $condition) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReferral = /* GraphQL */ `
  mutation UpdateReferral(
    $input: UpdateReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    updateReferral(input: $input, condition: $condition) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReferral = /* GraphQL */ `
  mutation DeleteReferral(
    $input: DeleteReferralInput!
    $condition: ModelReferralConditionInput
  ) {
    deleteReferral(input: $input, condition: $condition) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOpportunity = /* GraphQL */ `
  mutation CreateOpportunity(
    $input: CreateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    createOpportunity(input: $input, condition: $condition) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOpportunity = /* GraphQL */ `
  mutation UpdateOpportunity(
    $input: UpdateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    updateOpportunity(input: $input, condition: $condition) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOpportunity = /* GraphQL */ `
  mutation DeleteOpportunity(
    $input: DeleteOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    deleteOpportunity(input: $input, condition: $condition) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRolodex = /* GraphQL */ `
  mutation CreateRolodex(
    $input: CreateRolodexInput!
    $condition: ModelRolodexConditionInput
  ) {
    createRolodex(input: $input, condition: $condition) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRolodex = /* GraphQL */ `
  mutation UpdateRolodex(
    $input: UpdateRolodexInput!
    $condition: ModelRolodexConditionInput
  ) {
    updateRolodex(input: $input, condition: $condition) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRolodex = /* GraphQL */ `
  mutation DeleteRolodex(
    $input: DeleteRolodexInput!
    $condition: ModelRolodexConditionInput
  ) {
    deleteRolodex(input: $input, condition: $condition) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInteraction = /* GraphQL */ `
  mutation CreateInteraction(
    $input: CreateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    createInteraction(input: $input, condition: $condition) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInteraction = /* GraphQL */ `
  mutation UpdateInteraction(
    $input: UpdateInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    updateInteraction(input: $input, condition: $condition) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInteraction = /* GraphQL */ `
  mutation DeleteInteraction(
    $input: DeleteInteractionInput!
    $condition: ModelInteractionConditionInput
  ) {
    deleteInteraction(input: $input, condition: $condition) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createGroupInvitation = /* GraphQL */ `
  mutation CreateGroupInvitation(
    $input: CreateGroupInvitationInput!
    $condition: ModelGroupInvitationConditionInput
  ) {
    createGroupInvitation(input: $input, condition: $condition) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateGroupInvitation = /* GraphQL */ `
  mutation UpdateGroupInvitation(
    $input: UpdateGroupInvitationInput!
    $condition: ModelGroupInvitationConditionInput
  ) {
    updateGroupInvitation(input: $input, condition: $condition) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteGroupInvitation = /* GraphQL */ `
  mutation DeleteGroupInvitation(
    $input: DeleteGroupInvitationInput!
    $condition: ModelGroupInvitationConditionInput
  ) {
    deleteGroupInvitation(input: $input, condition: $condition) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThread = /* GraphQL */ `
  mutation CreateThread(
    $input: CreateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    createThread(input: $input, condition: $condition) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThread = /* GraphQL */ `
  mutation UpdateThread(
    $input: UpdateThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    updateThread(input: $input, condition: $condition) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThread = /* GraphQL */ `
  mutation DeleteThread(
    $input: DeleteThreadInput!
    $condition: ModelThreadConditionInput
  ) {
    deleteThread(input: $input, condition: $condition) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThreadContent = /* GraphQL */ `
  mutation CreateThreadContent(
    $input: CreateThreadContentInput!
    $condition: ModelThreadContentConditionInput
  ) {
    createThreadContent(input: $input, condition: $condition) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThreadContent = /* GraphQL */ `
  mutation UpdateThreadContent(
    $input: UpdateThreadContentInput!
    $condition: ModelThreadContentConditionInput
  ) {
    updateThreadContent(input: $input, condition: $condition) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThreadContent = /* GraphQL */ `
  mutation DeleteThreadContent(
    $input: DeleteThreadContentInput!
    $condition: ModelThreadContentConditionInput
  ) {
    deleteThreadContent(input: $input, condition: $condition) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThreadSubscriptions = /* GraphQL */ `
  mutation CreateThreadSubscriptions(
    $input: CreateThreadSubscriptionsInput!
    $condition: ModelThreadSubscriptionsConditionInput
  ) {
    createThreadSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThreadSubscriptions = /* GraphQL */ `
  mutation UpdateThreadSubscriptions(
    $input: UpdateThreadSubscriptionsInput!
    $condition: ModelThreadSubscriptionsConditionInput
  ) {
    updateThreadSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThreadSubscriptions = /* GraphQL */ `
  mutation DeleteThreadSubscriptions(
    $input: DeleteThreadSubscriptionsInput!
    $condition: ModelThreadSubscriptionsConditionInput
  ) {
    deleteThreadSubscriptions(input: $input, condition: $condition) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createThreadRequest = /* GraphQL */ `
  mutation CreateThreadRequest(
    $input: CreateThreadRequestInput!
    $condition: ModelThreadRequestConditionInput
  ) {
    createThreadRequest(input: $input, condition: $condition) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateThreadRequest = /* GraphQL */ `
  mutation UpdateThreadRequest(
    $input: UpdateThreadRequestInput!
    $condition: ModelThreadRequestConditionInput
  ) {
    updateThreadRequest(input: $input, condition: $condition) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteThreadRequest = /* GraphQL */ `
  mutation DeleteThreadRequest(
    $input: DeleteThreadRequestInput!
    $condition: ModelThreadRequestConditionInput
  ) {
    deleteThreadRequest(input: $input, condition: $condition) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
