/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      created
      updated
      type
      name
      bio
      image {
        key
        type
        size
        __typename
      }
      admins
      members
      tags
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        created
        updated
        type
        name
        bio
        image {
          key
          type
          size
          __typename
        }
        admins
        members
        tags
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessageChain = /* GraphQL */ `
  query GetMessageChain($id: ID!) {
    getMessageChain(id: $id) {
      id
      type
      members
      name
      last_timestamp
      last_message
      last_sender
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessageChains = /* GraphQL */ `
  query ListMessageChains(
    $filter: ModelMessageChainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageChains(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        members
        name
        last_timestamp
        last_message
        last_sender
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      message_chain
      content
      timestamp
      sender_id
      sender_org
      read
      hyperlinks {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        message_chain
        content
        timestamp
        sender_id
        sender_org
        read
        hyperlinks {
          text
          url
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      timestamp
      name
      source_id
      user_id
      target_ids
      meta_data
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timestamp
        name
        source_id
        user_id
        target_ids
        meta_data
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messageChainsByTypeAndLast_timestamp = /* GraphQL */ `
  query MessageChainsByTypeAndLast_timestamp(
    $type: String!
    $last_timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageChainFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messageChainsByTypeAndLast_timestamp(
      type: $type
      last_timestamp: $last_timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        members
        name
        last_timestamp
        last_message
        last_sender
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const messagesByMessage_chainAndTimestamp = /* GraphQL */ `
  query MessagesByMessage_chainAndTimestamp(
    $message_chain: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByMessage_chainAndTimestamp(
      message_chain: $message_chain
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message_chain
        content
        timestamp
        sender_id
        sender_org
        read
        hyperlinks {
          text
          url
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      query_name
      status
      type
      specific_type
      website
      address
      bio
      members
      profile_image {
        key
        type
        size
        __typename
      }
      last_seen
      children
      parent
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        query_name
        status
        type
        specific_type
        website
        address
        bio
        members
        profile_image {
          key
          type
          size
          __typename
        }
        last_seen
        children
        parent
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      status
      sub
      organization_ids
      outer_tags
      inner_tags
      properties
      first_name
      last_name
      social_links {
        text
        url
        type
        __typename
      }
      notifications {
        id
        enabled
        schedule
        __typename
      }
      phone
      position
      bio
      profile_image {
        key
        type
        size
        __typename
      }
      post_created
      referral_org
      referral_user
      signup_timestamp
      last_seen
      expiration
      subscription
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        status
        sub
        organization_ids
        outer_tags
        inner_tags
        properties
        first_name
        last_name
        social_links {
          text
          url
          type
          __typename
        }
        notifications {
          id
          enabled
          schedule
          __typename
        }
        phone
        position
        bio
        profile_image {
          key
          type
          size
          __typename
        }
        post_created
        referral_org
        referral_user
        signup_timestamp
        last_seen
        expiration
        subscription
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getWaitlist = /* GraphQL */ `
  query GetWaitlist($id: ID!) {
    getWaitlist(id: $id) {
      id
      email
      timestamp
      referred_by
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listWaitlists = /* GraphQL */ `
  query ListWaitlists(
    $filter: ModelWaitlistFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWaitlists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        timestamp
        referred_by
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPageView = /* GraphQL */ `
  query GetPageView($id: ID!) {
    getPageView(id: $id) {
      id
      views
      share_count
      refer_count
      opportunity_count
      last_viewed
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPageViews = /* GraphQL */ `
  query ListPageViews(
    $filter: ModelPageViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPageViews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        views
        share_count
        refer_count
        opportunity_count
        last_viewed
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getReferral = /* GraphQL */ `
  query GetReferral($id: ID!) {
    getReferral(id: $id) {
      id
      status
      referral_org
      referral_user
      referral_group
      timestamp
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listReferrals = /* GraphQL */ `
  query ListReferrals(
    $filter: ModelReferralFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReferrals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        referral_org
        referral_user
        referral_group
        timestamp
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      status
      type
      timestamp
      org_id
      user_id
      group_id
      group_ids
      title
      description
      time_zone
      start_date
      start_time
      end_date
      end_time
      location
      media {
        key
        type
        size
        __typename
      }
      tags
      outer_tags
      inner_tags
      properties
      asks {
        title
        description
        emoji
        __typename
      }
      links {
        text
        url
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        type
        timestamp
        org_id
        user_id
        group_id
        group_ids
        title
        description
        time_zone
        start_date
        start_time
        end_date
        end_time
        location
        media {
          key
          type
          size
          __typename
        }
        tags
        outer_tags
        inner_tags
        properties
        asks {
          title
          description
          emoji
          __typename
        }
        links {
          text
          url
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const postsByStatusAndTimestamp = /* GraphQL */ `
  query PostsByStatusAndTimestamp(
    $status: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByStatusAndTimestamp(
      status: $status
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        type
        timestamp
        org_id
        user_id
        group_id
        group_ids
        title
        description
        time_zone
        start_date
        start_time
        end_date
        end_time
        location
        media {
          key
          type
          size
          __typename
        }
        tags
        outer_tags
        inner_tags
        properties
        asks {
          title
          description
          emoji
          __typename
        }
        links {
          text
          url
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOpportunity = /* GraphQL */ `
  query GetOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      status
      timestamp
      members
      content_ids
      content {
        user_id
        content_id
        type
        inner_tags
        outer_tags
        __typename
      }
      data {
        user_id
        notes
        status
        updated
        email_timestamp
        __typename
      }
      tags
      groups
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOpportunities = /* GraphQL */ `
  query ListOpportunities(
    $filter: ModelOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOpportunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        status
        timestamp
        members
        content_ids
        content {
          user_id
          content_id
          type
          inner_tags
          outer_tags
          __typename
        }
        data {
          user_id
          notes
          status
          updated
          email_timestamp
          __typename
        }
        tags
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const opportunitiesByStatusAndTimestamp = /* GraphQL */ `
  query OpportunitiesByStatusAndTimestamp(
    $status: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOpportunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    opportunitiesByStatusAndTimestamp(
      status: $status
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        timestamp
        members
        content_ids
        content {
          user_id
          content_id
          type
          inner_tags
          outer_tags
          __typename
        }
        data {
          user_id
          notes
          status
          updated
          email_timestamp
          __typename
        }
        tags
        groups
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRolodex = /* GraphQL */ `
  query GetRolodex($id: ID!) {
    getRolodex(id: $id) {
      id
      source_user
      updated
      created
      first_name
      last_name
      emails
      job_title
      company
      locations
      inner_tags
      outer_tags
      description
      social_links
      notes {
        created
        updated
        note
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRolodexes = /* GraphQL */ `
  query ListRolodexes(
    $filter: ModelRolodexFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRolodexes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source_user
        updated
        created
        first_name
        last_name
        emails
        job_title
        company
        locations
        inner_tags
        outer_tags
        description
        social_links
        notes {
          created
          updated
          note
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rolodexesBySource_user = /* GraphQL */ `
  query RolodexesBySource_user(
    $source_user: String!
    $sortDirection: ModelSortDirection
    $filter: ModelRolodexFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rolodexesBySource_user(
      source_user: $source_user
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source_user
        updated
        created
        first_name
        last_name
        emails
        job_title
        company
        locations
        inner_tags
        outer_tags
        description
        social_links
        notes {
          created
          updated
          note
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getInteraction = /* GraphQL */ `
  query GetInteraction($id: ID!) {
    getInteraction(id: $id) {
      id
      source_user
      target_user
      timestamp
      url
      content_id
      content_type
      action
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listInteractions = /* GraphQL */ `
  query ListInteractions(
    $filter: ModelInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInteractions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        source_user
        target_user
        timestamp
        url
        content_id
        content_type
        action
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const interactionsBySource_userAndTimestamp = /* GraphQL */ `
  query InteractionsBySource_userAndTimestamp(
    $source_user: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInteractionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    interactionsBySource_userAndTimestamp(
      source_user: $source_user
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        source_user
        target_user
        timestamp
        url
        content_id
        content_type
        action
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGroupInvitation = /* GraphQL */ `
  query GetGroupInvitation($id: ID!) {
    getGroupInvitation(id: $id) {
      id
      status
      admin
      sender_id
      invitee_id
      group_id
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listGroupInvitations = /* GraphQL */ `
  query ListGroupInvitations(
    $filter: ModelGroupInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        admin
        sender_id
        invitee_id
        group_id
        created
        updated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThread = /* GraphQL */ `
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      owner_id
      status
      type
      security_level
      ai_assisted
      permissions {
        user_id
        role
        created
        updated
        __typename
      }
      members
      media {
        key
        type
        size
        __typename
      }
      tags
      title
      logo
      custom_logo {
        key
        type
        size
        __typename
      }
      content
      parent
      expiration
      created
      updated
      last_sender
      last_content
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThreads = /* GraphQL */ `
  query ListThreads(
    $filter: ModelThreadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner_id
        status
        type
        security_level
        ai_assisted
        permissions {
          user_id
          role
          created
          updated
          __typename
        }
        members
        media {
          key
          type
          size
          __typename
        }
        tags
        title
        logo
        custom_logo {
          key
          type
          size
          __typename
        }
        content
        parent
        expiration
        created
        updated
        last_sender
        last_content
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadContent = /* GraphQL */ `
  query GetThreadContent($id: ID!) {
    getThreadContent(id: $id) {
      id
      thread_id
      owner_id
      type
      sub_type
      index
      status
      priority
      content
      media {
        key
        type
        size
        __typename
      }
      mentions
      metrics {
        goal
        progress
        description
        unit
        created
        updated
        __typename
      }
      tasks {
        id
        description
        status
        assignee_ids
        due_date
        updated
        __typename
      }
      dates {
        name
        date
        __typename
      }
      reactions {
        reaction
        user_id
        __typename
      }
      choices
      responses {
        user_id
        response
        __typename
      }
      parent
      children
      expiration
      created
      updated
      scheduled
      reminder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThreadContents = /* GraphQL */ `
  query ListThreadContents(
    $filter: ModelThreadContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        thread_id
        owner_id
        type
        sub_type
        index
        status
        priority
        content
        media {
          key
          type
          size
          __typename
        }
        mentions
        metrics {
          goal
          progress
          description
          unit
          created
          updated
          __typename
        }
        tasks {
          id
          description
          status
          assignee_ids
          due_date
          updated
          __typename
        }
        dates {
          name
          date
          __typename
        }
        reactions {
          reaction
          user_id
          __typename
        }
        choices
        responses {
          user_id
          response
          __typename
        }
        parent
        children
        expiration
        created
        updated
        scheduled
        reminder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const threadContentsByThread_id = /* GraphQL */ `
  query ThreadContentsByThread_id(
    $thread_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelThreadContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadContentsByThread_id(
      thread_id: $thread_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thread_id
        owner_id
        type
        sub_type
        index
        status
        priority
        content
        media {
          key
          type
          size
          __typename
        }
        mentions
        metrics {
          goal
          progress
          description
          unit
          created
          updated
          __typename
        }
        tasks {
          id
          description
          status
          assignee_ids
          due_date
          updated
          __typename
        }
        dates {
          name
          date
          __typename
        }
        reactions {
          reaction
          user_id
          __typename
        }
        choices
        responses {
          user_id
          response
          __typename
        }
        parent
        children
        expiration
        created
        updated
        scheduled
        reminder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadSubscriptions = /* GraphQL */ `
  query GetThreadSubscriptions($id: ID!) {
    getThreadSubscriptions(id: $id) {
      id
      user_id
      thread_id
      status
      last_read
      notification_settings
      priority
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThreadSubscriptions = /* GraphQL */ `
  query ListThreadSubscriptions(
    $filter: ModelThreadSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadSubscriptions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        thread_id
        status
        last_read
        notification_settings
        priority
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const threadSubscriptionsByUser_id = /* GraphQL */ `
  query ThreadSubscriptionsByUser_id(
    $user_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelThreadSubscriptionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadSubscriptionsByUser_id(
      user_id: $user_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        user_id
        thread_id
        status
        last_read
        notification_settings
        priority
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getThreadRequest = /* GraphQL */ `
  query GetThreadRequest($id: ID!) {
    getThreadRequest(id: $id) {
      id
      thread_id
      requester_id
      sender_id
      status
      reason
      created
      updated
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listThreadRequests = /* GraphQL */ `
  query ListThreadRequests(
    $filter: ModelThreadRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        thread_id
        requester_id
        sender_id
        status
        reason
        created
        updated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const threadRequestsByThread_id = /* GraphQL */ `
  query ThreadRequestsByThread_id(
    $thread_id: String!
    $sortDirection: ModelSortDirection
    $filter: ModelThreadRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    threadRequestsByThread_id(
      thread_id: $thread_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        thread_id
        requester_id
        sender_id
        status
        reason
        created
        updated
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
