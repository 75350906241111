import _ from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ValueDisplay} from './drop-down';

export const FormikTextfield = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    type = 'text',
    autoComplete,
    ...rest
  } = props;

  const handleBlur = () => {
    const trimmed = formik?.values?.[name]?.trim();
    if (trimmed) {
      formik.setFieldValue(name, trimmed);
    }
  };

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          {...rest}
          type={type}
          {...formik.getFieldProps(name)}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
          autoComplete={autoComplete}
          onBlur={handleBlur}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const FormikSubtleTextfield = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    type = 'text',
    autoComplete,
    ...rest
  } = props;

  const handleBlur = () => {
    const trimmed = formik?.values?.[name]?.trim();
    if (trimmed) {
      formik.setFieldValue(name, trimmed);
    }
  };

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          className="subtle-textfield"
          style={{border: 'none'}}
          {...rest}
          type={type}
          {...formik.getFieldProps(name)}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
          autoComplete={autoComplete}
          onBlur={handleBlur}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const MultilineFormikSubtleTextfield = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    autofocus,
    type = 'text',
    ...rest
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const handleBlur = () => {
    const trimmed = formik?.values?.[name]?.trim();
    if (trimmed) {
      formik.setFieldValue(name, trimmed);
    }
  };

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <textarea
          className="subtle-textfield"
          ref={inputRef}
          {...rest}
          {...formik.getFieldProps(name)}
          type={type}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
          onBlur={handleBlur}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const FormikTextfieldSocialArray = props => {
  const {
    name,
    header,
    socialPlatform,
    type = 'text',
    autoComplete,
    formik,
    ...rest
  } = props;

  const [currentValue, setCurrentValue] = useState('');

  useEffect(() => {
    // Find and set the current value for the specified social platform
    const fieldArray = formik?.values[name] || [];
    const existingValue = fieldArray?.find(link =>
      link.includes(socialPlatform),
    );
    setCurrentValue(existingValue || '');
  }, [formik, name, socialPlatform]);

  const addProtocolIfMissing = url => {
    if (!url || url?.length < 1) return url;
    if (!url.match(/^(https?:\/\/|www\.)/i)) {
      return `https://${url}`;
    }
    return url;
  };

  const debouncedUpdate = useCallback(
    _.debounce(() => {
      const value = addProtocolIfMissing(currentValue);
      const fieldArray = formik.values[name] || [];
      const existingValueIndex = fieldArray.findIndex(link =>
        link.includes(socialPlatform),
      );

      if (existingValueIndex !== -1) {
        // Replace the existing value for the social platform
        const newArray = [...fieldArray];
        newArray[existingValueIndex] = value;
        formik.setFieldValue(name, newArray);
      } else {
        // Append the new value if the platform is not already in the array
        formik.setFieldValue(name, [...fieldArray, value]);
      }
    }, 1000),
    [formik, name, socialPlatform],
  );

  const handleChange = e => {
    const value = e.target.value?.trim();
    setCurrentValue(value);
    // debouncedUpdate(value);
  };

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          {...rest}
          type={type}
          value={currentValue}
          onChange={handleChange}
          onBlur={() => {
            debouncedUpdate();
          }}
          autoComplete={autoComplete}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const MultilineFormikTextfield = props => {
  const {
    formik,
    name,
    header,
    onEnter,
    autofocus,
    type = 'text',
    ...rest
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  const handleBlur = () => {
    const trimmed = formik?.values?.[name]?.trim();
    if (trimmed) {
      formik.setFieldValue(name, trimmed);
    }
  };

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <textarea
          ref={inputRef}
          {...rest}
          {...formik.getFieldProps(name)}
          type={type}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
          onBlur={handleBlur}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const ArrayFormikTextfield = props => {
  const {formik, name, header, comma, type = 'text', ...rest} = props;
  const {value} = formik.getFieldProps(name) || {};
  const [text, setText] = useState('');

  const append = useCallback(
    val => {
      let newValue;
      if (comma) {
        const values = val?.split(',');
        const trimmed = values.map(v => v?.trim()).filter(Boolean);
        newValue = [...(value || []), ...trimmed];
      } else {
        const trimmed = val?.trim();
        if (trimmed) {
          newValue = [...(value || []), trimmed];
        }
      }
      if (newValue) {
        formik.setFieldValue(name, newValue);
        formik.setFieldTouched(name, true, false);
      }
      setText('');
    },
    [comma, formik, name, value],
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        append(text);
      }
    },
    [append, text],
  );

  const handleRemove = useCallback(
    index => {
      const newValue = value.filter((_, i) => i !== index);
      formik.setFieldValue(name, newValue);
      formik.setFieldTouched(name, true, false);
    },
    [formik, name, value],
  );

  return (
    <div>
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          {...rest}
          type={type}
          value={text}
          onChange={e => setText(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={() => append(text)}
        />
      </div>
      <div className="flex-row-wrap">
        {value?.length
          ? value.map((val, index) => (
              <ValueDisplay
                key={`${val}-${index}`}
                value={val}
                onClick={() => handleRemove(index)}
              />
            ))
          : null}
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};

export const Textfield = props => {
  const {header, onEnter, type = 'text', ...rest} = props;

  return (
    <div>
      {header && <label>{header}</label>}
      <input
        {...rest}
        type={type}
        onKeyDown={e => {
          if (onEnter && e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
      />
    </div>
  );
};

export const SearchTextfield = props => {
  const {header, onEnter, type = 'text', ...rest} = props;
  return (
    <>
      <div className="input-wrapper">
        <input
          {...rest}
          // type={type}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
        />
      </div>
    </>
  );
};

export const MultilineTextfield = props => {
  const {
    header,
    onEnter,
    type = 'text',
    autofocus,
    focus,
    temp,
    ...rest
  } = props;

  const inputRef = useRef();

  useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <div ref={inputRef}>
      {header && <label>{header}</label>}
      <textarea
        {...rest}
        type={type}
        onKeyDown={e => {
          if (onEnter && e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
      />
    </div>
  );
};

export const DynamicTextfield = props => {
  const {header, onEnter, type = 'text', ...rest} = props;
  const textAreaRef = useRef(null);
  const maxRows = 5; // maximum number of rows
  const lineHeight = 21; // height of each row in pixels

  const resizeTextArea = () => {
    const textArea = textAreaRef.current;
    textArea.style.height = 'auto'; // temporarily shrink textarea to fit new content
    textArea.style.height = `${Math.min(
      textArea.scrollHeight,
      maxRows * lineHeight,
    )}px`; // resize the textarea
  };

  useEffect(() => {
    resizeTextArea(); // initial resize
  }, []);

  return (
    <div className="dynamic-textfield">
      {header && <label>{header}</label>}
      <textarea
        {...rest}
        ref={textAreaRef}
        type={type}
        onKeyDown={e => {
          if (onEnter && e.key === 'Enter') {
            e.preventDefault();
            onEnter();
          }
        }}
        onInput={resizeTextArea} // resize whenever the text changes
      />
    </div>
  );
};

export const FormikPhoneInput = props => {
  const {formik, name, header, onEnter, autoComplete, ...rest} = props;

  const formik_props = formik.getFieldProps(name);
  const {value} = formik_props;

  const [pretty, setPretty] = useState(value);

  useEffect(() => {
    const pretty_string = prettify(value);
    setPretty(pretty_string);
  }, []);

  const prettify = val => {
    let cleaned = val.replace(/[\(\)\-]/g, '').slice(0, 10);
    let pretty_string = '';

    if (cleaned.length <= 3) {
      pretty_string = cleaned;
    }

    if (cleaned.length > 3) {
      pretty_string += '(' + cleaned.slice(0, 3) + ')' + cleaned.slice(3, 6);
    }
    if (cleaned.length > 6) {
      pretty_string += '-' + cleaned.slice(6, 10);
    }
    return pretty_string;
  };

  return (
    <div className="formik-phone-input">
      {header && <label>{header}</label>}
      <div className="input-wrapper">
        <input
          {...rest}
          {...formik_props}
          type={'tel'}
          value={pretty}
          onChange={e => {
            const val = e.target.value;
            let cleaned = val.replace(/[\(\)\-]/g, '').slice(0, 10);
            formik.setFieldValue(name, cleaned);
            const pretty_string = prettify(cleaned);
            setPretty(pretty_string);
          }}
          onKeyDown={e => {
            if (onEnter && e.key === 'Enter') {
              e.preventDefault();
              onEnter();
            }
          }}
          autoComplete={autoComplete}
        />
      </div>
      {formik.touched[name] && formik.errors[name] && (
        <p className="input-error">{formik.errors[name]}</p>
      )}
    </div>
  );
};
