import {useFormik} from 'formik';
import React, {useEffect, useState} from 'react';
import {RxCheckCircled} from 'react-icons/rx';
import {useNavigate, useParams} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import {sendJoinThreadRequestEmail} from '../../../api/email';
import {setThreadRequest} from '../../../api/graphql';
import ActionPopup from '../../../components/action-feedback-popup/action-feedback-popup';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {TooltipRight} from '../../../components/tooltip/tooltip';
import {useAuth} from '../../../hooks/use-auth';
import {useThreads} from '../../../hooks/use-threads';
import './ThreadInviteLink.css';

const SIGNUP_LINK =
  '/signup?status=VTJGc2RHVmtYMStPNk40S2Zhb2huVmdqOVpPajJvTTdKd3lYYnIvdmlvRT0';

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required'),
});

const ThreadInviteLink = () => {
  const navigate = useNavigate();
  const {id} = useParams();
  const {
    state: {threads},
    getThread,
    updateThread,
  } = useThreads();
  const {
    state: {id: cur_user_id, profile},
  } = useAuth();

  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [popup, setPopup] = useState({on: false, message: ''});

  const thread = threads?.[id];
  const {title, content, members, permissions, security_level, logo} =
    thread || {};

  useEffect(() => {
    if (profile) {
      setComplete(members?.includes(profile.email));
    }
  }, [members, profile]);

  useEffect(() => {
    const loadThread = async () => {
      setLoading(true);
      await getThread(id);
      setLoading(false);
    };

    if (id && !thread) {
      loadThread();
    }
  }, [id, thread, getThread]);

  const handleSubmit = async (values, helpers) => {
    try {
      const {email} = values;
      if (members?.includes(email)) {
        helpers.setErrors({email: 'Email already subscribed'});
        return;
      }

      if (security_level === 'secure') {
        await handleSecureJoinRequest(email);
      } else {
        await handleAutoJoin(email);
      }

      setComplete(true);
      helpers.resetForm();
    } catch (err) {
      helpers.setErrors({submit: err.message});
      console.error(err);
    }
  };

  const handleSecureJoinRequest = async email => {
    const now = dateToTimestamp();
    const request = {
      id: v4(),
      thread_id: id,
      requester_id: email,
      sender_id: null,
      status: 'requested',
      reason: 'Through invite link.',
      created: now,
      updated: now,
    };
    await setThreadRequest(request);
    await sendJoinThreadRequestEmail({thread, profile: profile || {id: email}});
    setPopup({on: true, message: 'Request sent!'});
  };

  const handleAutoJoin = async email => {
    const now = dateToTimestamp();
    const merged = [...(members || []), email];
    const perms = [
      ...(permissions || []),
      {
        user_id: email,
        role: security_level !== 'secure' ? 'collaborator' : 'subscriber',
        created: now,
        updated: now,
      },
    ];
    const {success, error} = await updateThread({
      id,
      members: merged,
      permissions: perms,
    });
    if (success) {
      track('thread_joined', {
        thread_id: id,
        user_email: email,
        join_method: 'invite_link',
      });
      setPopup({on: true, message: 'Success!'});
    }
    if (error) {
      throw new Error(error);
    }
  };

  const formik = useFormik({
    initialValues: {email: profile?.id || ''},
    validationSchema,
    onSubmit: handleSubmit,
  });

  if (loading) {
    return (
      <div className="thread-invite-container loading-indicator">
        <SpinningIndicator />
      </div>
    );
  }

  if (!id || !thread) {
    return (
      <div className="thread-invite-container">
        <p className="error-message">What you're looking for doesn't exist.</p>
      </div>
    );
  }

  return (
    <div className="thread-invite-container">
      <ThreadInviteHeader
        securityLevel={security_level}
        title={title}
        profile={profile}
      />
      <ThreadDetails title={title} content={content} logo={logo} />
      <InviteForm
        formik={formik}
        complete={complete}
        profile={profile}
        securityLevel={security_level}
        threadId={id}
      />
      <ActionPopup message={popup.message} setOff={setPopup} on={popup.on} />
    </div>
  );
};

const ThreadInviteHeader = ({securityLevel, title, profile}) => (
  <div className="thread-invite-header">
    <SecurityLabel securityLevel={securityLevel} />
    <h1 className="thread-invite-title">Join the Conversation</h1>
    <p className="thread-invite-subtitle">
      {profile ? 'Join' : 'Subscribe to'} this thread and stay updated
    </p>
  </div>
);

const SecurityLabel = ({securityLevel}) => {
  const securityInfo = {
    secure: {text: 'Secure', tooltip: 'Maximum Security Thread'},
    private: {text: 'Private', tooltip: 'Invite-only Security Thread'},
    open: {text: 'Public', tooltip: 'Public Thread'},
  };

  const {text, tooltip} = securityInfo[securityLevel] || securityInfo.open;

  return (
    <TooltipRight text={tooltip}>
      <span className={`security-icon ${securityLevel}`}>{text}</span>
    </TooltipRight>
  );
};

const ThreadDetails = ({title, content, logo}) => (
  <div className="thread-details">
    <p className="text-secondary">Thread Details</p>
    <h2 className="thread-details-title">
      {logo} {title}
    </h2>
    <p className="thread-details-content">{content}</p>
  </div>
);

const InviteForm = ({formik, complete, profile, securityLevel, threadId}) => {
  const navigate = useNavigate();

  if (complete) {
    return (
      <CompleteSection
        profile={profile}
        securityLevel={securityLevel}
        threadId={threadId}
      />
    );
  }

  return (
    <form onSubmit={formik.handleSubmit} className="invite-form">
      {!profile ? (
        <button
          className="invite-submit-button"
          onClick={() => navigate(SIGNUP_LINK)}>
          Click here to join
        </button>
      ) : (
        <>
          <div className="invite-form-group">
            <label htmlFor="email" className="invite-form-label">
              Email
            </label>
            <input
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              className="invite-form-input"
              placeholder="Enter your email address"
              disabled={!!profile?.id}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="invite-form-error">{formik.errors.email}</div>
            )}
          </div>
          {formik.errors.submit && (
            <div className="invite-form-error">{formik.errors.submit}</div>
          )}
          <button
            type="submit"
            className="invite-submit-button"
            disabled={formik.isSubmitting}>
            {formik.isSubmitting
              ? `${securityLevel === 'secure' ? 'Requesting...' : 'Joining...'}`
              : `${securityLevel === 'secure' ? 'Request to Join' : 'Join'}`}
          </button>
        </>
      )}
    </form>
  );
};

const CompleteSection = ({profile, securityLevel, threadId}) => {
  const navigate = useNavigate();

  if (!profile) {
    return (
      <div className="flex-column align-center">
        <RxCheckCircled size={'25px'} color="green" />
        <p className="text-bold text-center padding-bottom8">Done</p>
        <button
          className="invite-submit-button"
          onClick={() => navigate(SIGNUP_LINK)}>
          Create account to continue
        </button>
      </div>
    );
  }

  if (securityLevel !== 'secure') {
    return (
      <div className="flex-column align-center">
        <RxCheckCircled size={'25px'} color="green" />
        <p className="text-bold text-center padding-bottom8">You're in!</p>
        <button
          className="invite-submit-button"
          onClick={() => navigate(`/threads/${threadId}`)}>
          Go to Thread
        </button>
      </div>
    );
  }

  return (
    <div className="flex-column align-center">
      <RxCheckCircled size={'25px'} color="green" />
      <p className="text-bold text-center padding-bottom8">
        Request Sent to Thread Owner
      </p>
      <p className="text-secondary text-center padding-bottom8">
        Thread Owner will respond as soon as possible
      </p>
      <button
        className="invite-submit-button"
        onClick={() => navigate('/threads/')}>
        Go Home
      </button>
    </div>
  );
};

export default ThreadInviteLink;
