import {useFormik} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {IoMdArrowBack} from 'react-icons/io';
import {RxLockClosed, RxLockOpen1, RxLockOpen2} from 'react-icons/rx';
import {useNavigate} from 'react-router-dom';
import {v4} from 'uuid';
import * as Yup from 'yup';
import {track} from '../../../api/analytics';
import {dateToTimestamp} from '../../../api/dates';
import {sendThreadInvitations} from '../../../api/email';
import tagsJSON from '../../../assets/lists/tags.json';
import {FormikArraySearchableDropDown} from '../../../components/inputs/drop-down';
import {
  BulkEmailUploader,
  PermissionsInput,
} from '../../../components/inputs/email-input';
import {EmojiInput} from '../../../components/inputs/emoji-input';
import {
  FormikTextfield,
  MultilineFormikTextfield,
} from '../../../components/inputs/textfields';
import {SpinningIndicator} from '../../../components/loading/loading-indicator';
import {Tab} from '../../../components/tab/tab';
import {useAuth} from '../../../hooks/use-auth';
import useLoader from '../../../hooks/use-loader';
import useStringFormatter from '../../../hooks/use-string-formatter';
import {useThreads} from '../../../hooks/use-threads';
import {RenderMemberContent} from '../modals/ThreadInfoPopup';
import './CreateThread.css';

const CreateThread = ({}) => {
  const [index, setIndex] = useState(0);
  const [toggleType, setToggleType] = useState('Open');
  const tabs = [
    {tab: 'Thread', index: 0},
    {tab: 'Direct', index: 1},
  ];

  return (
    <div className="page-container">
      <Tab items={tabs} index={index} setIndex={setIndex} />
      {index === 0 && (
        <TypeToggle toggleType={toggleType} setToggleType={setToggleType} />
      )}

      {index === 0 && <CreateThreadChain toggleType={toggleType} />}
      {index === 1 && <CreateDM />}
    </div>
  );
};

const CreateDM = ({}) => {
  const navigate = useNavigate();
  const {loadProfiles} = useLoader();

  const {prettyName} = useStringFormatter();
  const {
    state: {id, profiles, profile},
  } = useAuth();
  const {createThread} = useThreads();

  const [file, setFile] = useState(null);
  const now = dateToTimestamp();

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      status: 'active',
      type: 'direct',
      security_level: 'secure',
      permissions: [{user_id: id, role: 'admin', created: now, updated: now}],
      media: [],
      tags: [],
      title: '',
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      permissions: Yup.array().min(2, 'You must pick at least one person.'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        values.title = permissions
          .map(permission =>
            prettyName(
              profiles?.[permission.user_id] ?? {
                id: permission.user_id,
                email: permission.user_id,
              },
            ),
          )
          .join(', ');

        const now = dateToTimestamp();

        const members = permissions.map(permission => permission.user_id);
        const thread = {
          ...values,
          members,
          created: now,
          updated: now,
        };

        if (file) {
          thread.media = [file];
        }
        const {success, error} = await createThread(thread);
        if (success) {
          track('dm_thread_created', {thread, user_id: id});
          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const other_members = members.filter(mem => mem !== id);
          if (other_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: other_members,
              thread,
              profile: prettyName(profile),
            });
          }
        }
        if (error) {
          // TODO: Handle error
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  const {permissions} = formik.values;

  useEffect(() => {
    const mapped = permissions.map(user => user.user_id);
    loadProfiles(mapped);
  }, [permissions]);

  return (
    <div className="create-thread">
      <IoMdArrowBack
        className="clickable padding-top8"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="create-thread__header">
        <h4>Create a Direct Thread</h4>
        <p className="text-secondary">
          Direct Threads have a security level of 'Secure'
        </p>
      </div>
      <div className="create-thread__form pretty-form-group">
        <PermissionsInput
          formik={formik}
          name="permissions"
          header="Thread Members"
        />
        <br />
        <p className="text-bold">Thread Members</p>

        <RenderMemberContent
          thread={formik.values}
          button={{
            text: 'Remove',
            onClick: async item => {
              const {user_id} = item;
              const {permissions} = formik?.values ?? {};
              const filtered_permissions = permissions?.filter(
                user => user.user_id !== user_id,
              );
              formik.setFieldValue('permissions', filtered_permissions);
            },
          }}
        />

        <div className="create-thread__submit-container">
          <button
            type="submit"
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Create Direct Thread
          </button>
        </div>
      </div>
    </div>
  );
};

const CreateThreadChain = ({toggleType}) => {
  const navigate = useNavigate();
  const {prettyName} = useStringFormatter();

  const {
    state: {id, profiles, profile},
  } = useAuth();
  const {createThread} = useThreads();

  const [file, setFile] = useState(null);
  const [emojiPicker, setEmoji] = useState(false);
  const now = dateToTimestamp();

  const formik = useFormik({
    initialValues: {
      id: v4(),
      owner_id: id,
      status: 'active',
      type: 'thread',
      security_level: toggleType.toLowerCase(),
      permissions: [{user_id: id, role: 'owner', created: now, updated: now}],
      media: [],
      // custom_logo: '',
      tags: [],
      title: '',
      content: '',
      logo: '',
      parent: null,
      expiration: null,
      ai_assisted: false,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
    }),

    onSubmit: async (values, helpers) => {
      try {
        const {permissions} = values;
        values.security_level = toggleType.toLowerCase();
        const members = permissions.map(permission => permission.user_id);
        const thread = {
          ...values,
          members,
          created: now,
          updated: now,
        };
        if (file) {
          thread.custom_logo = file;
          // thread.media = [file];
        }
        const {success, error} = await createThread(thread);
        if (success) {
          track('thread_created', {thread, user_id: id});
          helpers.resetForm();
          navigate(`/threads/${thread?.id}`);
          const other_members = members.filter(mem => mem !== id);
          if (other_members?.length) {
            const {success, error} = await sendThreadInvitations({
              emails: other_members,
              thread,
              profile: prettyName(profile),
            });
          }
        }
        if (error) {
          // TODO
        }
      } catch (err) {
        helpers.setStatus({success: false});
        helpers.setErrors({submit: err.message});
        helpers.setSubmitting(false);
      }
    },
  });

  return (
    <div className="create-thread">
      <IoMdArrowBack
        className="clickable padding-top8"
        onClick={() => {
          navigate(-1);
        }}
      />
      <div className="create-thread__header">
        <h4>
          Create a{' '}
          <strong>{toggleType === 'Open' ? 'Public' : toggleType}</strong>{' '}
          Thread
        </h4>
        <p className="text-secondary">
          Threads are a secure way to host and work on your projects. You can
          choose the security level above and add details and members below.
        </p>
      </div>

      <div className="create-thread__form pretty-form-group">
        <ThreadLogoUpload
          formik={formik}
          emojiPicker={emojiPicker}
          setEmoji={setEmoji}
        />
        <div className="create-thread__field ">
          <FormikTextfield
            formik={formik}
            name={'title'}
            header={`Thread Title ${
              toggleType.toLowerCase() !== 'secure' ? '(Public)' : '(Private)'
            }`}
          />
        </div>
        <div className="create-thread__field">
          <MultilineFormikTextfield
            formik={formik}
            name={'content'}
            header={`Thread Description ${
              toggleType.toLowerCase() !== 'secure' ? '(Public)' : '(Private)'
            }`}
            rows={8}
          />
        </div>
        <br />
        <div className="create-thread__header-small">
          <h5>Bulk Add Collaborators</h5>
          <p className="text-secondary">
            Collaborators are people working on the project with you. Add a CSV
            or copy and paste emails to bulk add them. Be sure to press the "Add
            Collaborators" button.
          </p>
        </div>
        <BulkEmailUploader formik={formik} />
        <div className="create-thread__header-small">
          <h5>Add Individuals and Specify Roles</h5>
          <p className="text-secondary">
            If you need to add other administrators to the project, do that
            here. Administrators have edit access on the thread. You will be
            able to invite more people after you create the thread via a simple
            link.
          </p>
        </div>
        <PermissionsInput
          formik={formik}
          name="permissions"
          header="Thread Members"
        />
        <br />
        <p className="text-bold">Thread Members</p>

        <RenderMemberContent
          thread={formik.values}
          button={{
            text: 'Remove',
            onClick: async item => {
              const {user_id} = item;
              const {permissions} = formik?.values ?? {};
              const filtered_permissions = permissions?.filter(
                user => user.user_id !== user_id,
              );
              formik.setFieldValue('permissions', filtered_permissions);
            },
          }}
        />

        <div className="create-thread__submit-container">
          <button
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Create{' '}
            <strong>{toggleType === 'Open' ? 'Public' : toggleType}</strong>{' '}
            Thread
          </button>
        </div>
      </div>
    </div>
  );
};

export const UpdateThread = ({formik, isSmall}) => {
  const [emojiPicker, setEmoji] = useState(false);
  const thread_type =
    formik?.values.security_level?.slice(0, 1)?.toUpperCase() +
      formik?.values.security_level?.slice(1) || 'Open';

  const [toggleType, setToggleType] = useState(thread_type);

  useEffect(() => {
    if (toggleType)
      formik.setFieldValue('security_level', toggleType.toLowerCase());
  }, [toggleType]);

  return (
    <div className="create-thread">
      <div className="create-thread__header">
        {!isSmall && <h4>Update Your Thread</h4>}
      </div>
      {formik?.values.security_level &&
        formik?.values.security_level !== 'secure' && (
          <PublicPrivateToggle
            toggleType={toggleType}
            setToggleType={setToggleType}
            minimal={true}
          />
        )}
      <div className="create-thread__form pretty-form-group">
        <ThreadLogoUpload
          formik={formik}
          emojiPicker={emojiPicker}
          setEmoji={setEmoji}
        />
        <div className="create-thread__field">
          <FormikTextfield
            formik={formik}
            name={'title'}
            header="Thread Title"
          />
        </div>
        <div className="create-thread__field">
          <MultilineFormikTextfield
            formik={formik}
            name={'content'}
            header="Thread Description"
            rows={8}
          />
        </div>

        <FormikArraySearchableDropDown
          formik={formik}
          header="Tags"
          name="tags"
          items={tagsJSON}
          mappings={{key: 'pretty', value: 'key', subtext: 'type'}}
        />
        <div className="create-thread__submit-container">
          <button
            type="submit"
            className="create-thread__submit-button"
            onClick={formik.handleSubmit}
            disabled={formik.isSubmitting}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateThread;

export const TypeToggle = ({toggleType, setToggleType, minimal = false}) => {
  const getDescriptionText = () => {
    switch (toggleType) {
      case 'Secure':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Invite-only. Not discoverable. For sensitive information.{' '}
            </p>
            <p className="text-bold text-red">
              This setting cannot be changed after creation
            </p>
          </span>
        );
      case 'Private':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Invite-only. Discoverable by members.
            </p>
            <p className="text-secondary">
              You can change this setting to Public later
            </p>
          </span>
        );
      case 'Open':
        return (
          <span className="flex-column align-center">
            <p className="text-secondary">
              Public. Anyone can discover and join.
            </p>
            <p className="text-secondary">
              You can change this setting to Private later
            </p>
          </span>
        );
      default:
        return '';
    }
  };
  const getLabelText = () => {
    switch (toggleType) {
      case 'Secure':
        return 'Secure Thread';
      case 'Private':
        return 'Private Thread';
      case 'Open':
        return 'Public Thread';
      default:
        return '';
    }
  };
  const getToggleClass = () => {
    if (toggleType === 'Secure') return 'secure-active';
    if (toggleType === 'Private') return 'private-active';
    return 'discoverable-active';
  };

  return (
    <div className="ai-toggle">
      <button
        className={`type-toggle-button ${getToggleClass()}`}
        aria-label="Toggle Thread Type">
        <span
          className="toggle-icon secure-icon"
          onClick={() => setToggleType('Secure')}>
          <RxLockClosed />
        </span>
        <span
          className="toggle-icon private-icon"
          onClick={() => setToggleType('Private')}>
          <RxLockOpen1 />
        </span>
        <span
          className="toggle-icon discoverable-icon"
          onClick={() => setToggleType('Open')}>
          <RxLockOpen2 />
        </span>
        <span className="toggle-slider"></span>
      </button>

      <p className="text-bold">{getLabelText()}</p>
      {!minimal && getDescriptionText()}
    </div>
  );
};

export const PublicPrivateToggle = ({
  toggleType,
  setToggleType,
  minimal = false,
}) => {
  const getDescriptionText = () => {
    switch (toggleType) {
      case 'Private':
        return 'Invite-only. Discoverable by members.';
      case 'Open':
        return 'Public. Anyone can discover and join.';
      default:
        return '';
    }
  };

  const getLabelText = () => {
    switch (toggleType) {
      case 'Private':
        return 'Private Thread';
      case 'Open':
        return 'Public Thread';
      default:
        return '';
    }
  };

  const getToggleClass = () => {
    return toggleType === 'Private'
      ? 'public-private-toggle__button--private'
      : 'public-private-toggle__button--open';
  };

  return (
    <div className="public-private-toggle">
      <button
        className={`public-private-toggle__button ${getToggleClass()}`}
        aria-label="Toggle Thread Type">
        <span
          className="public-private-toggle__icon public-private-toggle__icon--private"
          onClick={() => setToggleType('Private')}>
          <RxLockOpen1 />
        </span>
        <span
          className="public-private-toggle__icon public-private-toggle__icon--open"
          onClick={() => setToggleType('Open')}>
          <RxLockOpen2 />
        </span>
        <span className="public-private-toggle__slider"></span>
      </button>
      <p className="public-private-toggle__label">{getLabelText()}</p>
      {!minimal && (
        <p className="public-private-toggle__description">
          {getDescriptionText()}
        </p>
      )}
    </div>
  );
};

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

const ThreadLogoUpload = ({formik, emojiPicker, setEmoji}) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = e => {
    const selectedFile = e.target.files[0];
    if (!selectedFile) return;

    if (selectedFile.size > MAX_FILE_SIZE) {
      alert(`File size exceeds the maximum limit of 5MB`);
      e.target.value = null;
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(selectedFile.type)) {
      alert('Invalid file type. Please use JPEG, PNG, or GIF.');
      e.target.value = null;
      return;
    }

    setFile(selectedFile);
    formik.setFieldValue('custom_logo', selectedFile);
    formik.setFieldValue('logo', ''); // Clear emoji when custom logo is set
  };

  const handleEmojiSelect = emoji => {
    formik.setFieldValue('logo', emoji);
    formik.setFieldValue('custom_logo', null); // Clear custom logo when emoji is set
    setFile(null);
  };

  const handleEditClick = () => {
    if (fileInputRef) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="thread-logo-upload">
      <label>Thread Logo</label>
      <EmojiInput
        emoji={formik.values.logo}
        setEmoji={handleEmojiSelect}
        setShowPicker={setEmoji}
        showPicker={emojiPicker}
      />
      <div className="logo-options">
        <span>or</span>
        <input
          ref={fileInputRef}
          id="thread_logo_input"
          type="file"
          accept={ALLOWED_FILE_TYPES.join(', ')}
          onChange={handleFileChange}
          style={{display: 'none'}}
        />
        <button onClick={handleEditClick}>
          {file ? 'Change Custom Logo' : 'Upload Custom Logo'}
        </button>
      </div>
      {file && (
        <div className="custom-logo-preview">
          <img src={URL.createObjectURL(file)} alt="Custom logo preview" />
          {loading ? (
            <SpinningIndicator />
          ) : (
            <button
              className="basic-button-secondary-critical"
              onClick={() => {
                formik.setFieldValue('custom_logo', null);
                setFile(null);
              }}>
              Remove
            </button>
          )}
        </div>
      )}
    </div>
  );
};
